import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { database } from "../../services/firebase-config";
import { collection, getDocs, doc, updateDoc, query, where, deleteDoc } from "firebase/firestore";
import CompanyRow from "./AccountRow";
import useDashboardStore from "../../store/useDashboardStore";


const SuperAdmin = (props) => {

    const navigate = useNavigate();
    const { plans,userPersonalData } = useDashboardStore();
    const [allAccounts, setAllAccounts] = useState([]);
    const [allOwnerCompanies, setAllOwnerCompanies] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);
    const [allAccountReg, setAllAccountReg] = useState([]);
    const [numberOfAccountInTrail, setNumberOfAccountInTrail] = useState(0);
    const [numberOfAccountInPaid, setNumberOfAccountInPaid] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const [licenseFilter, setLicenseFilter] = useState("");
    const [validityFilter, setValidityFilter] = useState("valid");



    const loadAdminData = async () => {
        setIsLoading(true);
        try {
            const dataRef = collection(database, 'accounts');
            const querySnapshot = await getDocs(dataRef);
            const accountList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const admins = accountList
              .filter(account => account.associateId === account.uuid)
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


            //const adminsWithEmployees = admins.map(admin => {const employees = accountList.filter(emp => emp.associateId === admin.associateId && emp.uuid !== admin.uuid); return { ...admin, employees: employees };});
            const adminsWithEmployees = admins.map(admin => {
              const employees = accountList.filter(emp => emp.associateId === admin.associateId && emp.uuid !== admin.uuid);
              const plan = plans.find(p => p.id === admin.planId);
              return { ...admin, employees, planName: plan?.planName || "ללא רישיון" };
          });
          


            const ownerCompanies = collection(database, 'companiesOwner');
            const ownerCompaniesSnapShot = await getDocs(ownerCompanies);
            const ownerCompaniesList = ownerCompaniesSnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const employees = collection(database, 'employees');
            const employeesSnapShot = await getDocs(employees);
            const employeesList = employeesSnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const companies = collection(database, 'companies');
            const companiesSnapShot = await getDocs(companies);
            const companiesList = companiesSnapShot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const trails = adminsWithEmployees.filter(x => x.license === 'Trail' || x.license === 'Free').length;
            const paids = adminsWithEmployees.filter(x => x.license === 'Paid').length;

            setNumberOfAccountInPaid(paids);
            setNumberOfAccountInTrail(trails);
            setAllAccounts(adminsWithEmployees);
            setAllOwnerCompanies(ownerCompaniesList);
            setAllEmployees(employeesList);
            setAllCompanies(companiesList);
            setAllAccountReg(accountList);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    };

    useEffect(() => {
        loadAdminData();
    }, []);

    // const filteredAccounts = searchQuery ? allAccounts.filter(
    //     account => account.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     account.taxId.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     account.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     account.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     account.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    // ) : allAccounts;





    async function loadDocumentsByAssociateId() {
        try {
          const collectionRef = collection(database, "findingList"); // Replace with your collection name
          const q = query(collectionRef, where("associateId", "==", "0AycwUZG7fYUg0EcDgWqehvGKpw1"));
          const querySnapshot = await getDocs(q);
      
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
      
          return documents;
        } catch (error) {
          console.error("Error loading documents:", error);
          throw error;
        }
      }
      
      // Call and log the data properly
      loadDocumentsByAssociateId()
        .then((documents) => {
          console.log("DATA:", documents); // Logs the documents array
        })
        .catch((error) => {
          console.error("Error:", error);
        });


        async function deleteDocumentsByAssociateId() {
            try {
              const collectionRef = collection(database, "findingList"); // Replace with your collection name
              const q = query(collectionRef, where("associateId", "==", "0AycwUZG7fYUg0EcDgWqehvGKpw1"));
              const querySnapshot = await getDocs(q);
          
              const deletePromises = querySnapshot.docs.map((document) =>
                deleteDoc(doc(database, "findingList", document.id))
              );
          
              await Promise.all(deletePromises); // Wait for all delete operations to complete
              console.log("All documents with associateId deleted successfully.");
            } catch (error) {
              console.error("Error deleting documents:", error);
            }
          }
      
          const filteredAccounts = allAccounts.filter(account => {
            // סינון לפי חיפוש טקסט
            const matchesSearch = searchQuery === "" || (
                account.companyName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                account.taxId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                account.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                account.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                account.lastName?.toLowerCase().includes(searchQuery.toLowerCase())
            );
        
            const matchesLicense = licenseFilter === "" || account.planName === licenseFilter;

        
            // סינון לפי תוקף
            const now = moment();
            const expirationDate = account?.expireDate
    ? moment(account.expireDate.toDate ? account.expireDate.toDate() : account.expireDate)
    : null;

            const isValid = !expirationDate || expirationDate.isSameOrAfter(now);
            const matchesValidity = validityFilter === "" ||
                (validityFilter === "valid" && isValid) ||
                (validityFilter === "expired" && !isValid);
        
            return matchesSearch && matchesLicense && matchesValidity;
        });
        


    return (
        <>
            <ToastContainer />
            <div className="row heading-container">
                <div className="col-lg-9">
                    <h3>ניהול ארגונים במערכת</h3>
                </div>
                <div className="col-lg-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="חיפוש לפי שם חברה או דואל"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            <div className="row" style={{ padding: 50 }}>


            <div className="row mb-3">
            <div className="col-md-3">
            <select className="form-select" value={licenseFilter} onChange={e => setLicenseFilter(e.target.value)}>
                <option value="">כל סוגי הרישיונות</option>
                {plans.map(plan => (
                    <option key={plan.id} value={plan.planName}>
                        {plan.planName}
                    </option>
                ))}
            </select>

            </div>
            <div className="col-md-3">
                <select className="form-select" value={validityFilter} onChange={e => setValidityFilter(e.target.value)}>
                    <option value="valid">בתוקף (ברירת מחדל)</option>
                    <option value="expired">לא בתוקף</option>
                    <option value="">הצג את כולם</option>
                </select>
            </div>
        </div>


                {
                    isLoading ? (
                        <div className="spinner-border text-info" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : (
                        allAccounts.length > 0 && (
                            <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">תאריך הרשמה</th>
                                    <th scope="col">שם מנהל החשבון</th>
                                    <th scope="col">חברה / ח״פ</th>
                                    <th scope="col">דואר אלקטרוני</th>
                                    <th scope="col">משתמשים</th>
                                    <th scope="col">חברות</th>
                                    <th scope="col">אתרים</th>
                                    <th scope="col">עובדים</th>
                                    <th scope="col">רישיון</th>
                                    <th scope="col">תוקף</th>
                                    <th scope="col">כניסה אחרונה</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                    {filteredAccounts.map(account => (
                                        <CompanyRow
                                            plans={plans}
                                            key={account.id}
                                            account={account}
                                            allCompanies={allCompanies}
                                            allEmployees={allEmployees}
                                            allOwnerCompanies={allOwnerCompanies}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        )
                    )
                }
            </div>
        </>
    );
};

export default SuperAdmin;
