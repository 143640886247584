import moment from "moment";
import React, { useState,useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { MdOutlineArrowForwardIos, MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import useDashboardStore from "../store/useDashboardStore";
import { database, storage } from "../services/firebase-config";
import { collection, addDoc,getDoc, doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";


const EmployeeCard = (props) => {

  const {
    userPersonalData,
    addUserToStore,
    companies,
    accounts
  } = useDashboardStore();

  const [isEditView, setIsEditView] = useState(false);
  const [firstName, setFirstName] = useState(props.selectedEmployee.firstName);
  const [lastName, setLastname] = useState(props.selectedEmployee.lastName);
  const [email, setEmail] = useState(props.selectedEmployee.email);
  const [address, setAddress] = useState(props.selectedEmployee.address);
  const [employeeId, setEmployeeId] = useState(props.selectedEmployee.employeeId);
  const [mobile, setMobile] = useState(props.selectedEmployee.phone);
  const [position, setPosition] = useState(props.selectedEmployee.position);
  const [avatar, setAvatar] = useState("");
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [department, setDepartment] = useState(props.selectedEmployee.department);
  const [employeeNumber, setEmployeeNumber] = useState(props.selectedEmployee.employeeNumber);
  const [hospitality, setHospitality] = useState(props.selectedEmployee.hospitality);
  const [addFileView, setAddFileView] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSource, setFileSource] = useState(null);
  const [fileExpDate, setFileExpDate] = useState();
  const [company, setCompany] = useState(props.selectedEmployee.company);
  const [constructor, setConstructor] = useState(props.selectedEmployee.constructor);
  const [branch, setBranch] = useState(props.selectedEmployee.branch);
  const [committeesList, setCommitteesList] = useState(props.selectedEmployee.trainings ? props.selectedEmployee.trainings.filter(x => x.itemType === 'ועדת בטיחות') : []);
  const [trainingsList, setTrainingsList] = useState(props.selectedEmployee.trainings ? props.selectedEmployee.trainings.filter(x => x.itemType === 'הדרכות') : []);


  useEffect(() => {
    if (department) {
      const filtered = props.departments.filter((dept) =>
        dept.departmentName.toLowerCase().includes(department.toLowerCase())
      );
      setFilteredDepartments(filtered);
      setShowDropdown(filtered.length > 0);
    } else {
      setFilteredDepartments([]);
      setShowDropdown(false);
    }
  }, [department, props.departments]);

  

  const handleSelectDepartment = (deptName) => {
    setDepartment(deptName);
    setShowDropdown(false);
  };
  const fileInputRef = useRef(null);
  const triggerFileInput = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };

  console.log("COMPA: " + props.company.id);
  const setAsMemberUser = async () => {
    const employee = props.selectedEmployee;
  
    if (!employee.email) {
      return toast.error('חובה להגדיר כתובת דואר אלקטרוני על מנת לפתוח משתמש לעובד');
    }
  
    const isEmailExist = accounts.filter(x => x.email === employee.email);
    if (isEmailExist.length > 0) {
      return toast.error('כתובת המייל כבר קיימת תחת חשבונות');
    }
  
    if (!employee.firstName || !employee.lastName || !employee.email) {
      return toast.error("יש למלא את כל השדות");
    }
  

    try {
      const company = companies.find(x => x.id === employee.associateCompany);
      const objid = generateRandomId();
      const newUser = {
        uuid: "",
        associateId: userPersonalData.associateId,
        expoPushToken: "",
        role: 'user',
        email: employee.email,
        avatar: employee.avatar || "https://res.cloudinary.com/united-app/image/upload/v1683366002/avatars/avatar_k4ntm3.png",
        contact: {
          phone: employee.phone || "",
          address: "",
          zip: "",
          city: "",
          country: "",
          state: "",
          signature: ""
        },
        firstName: employee.firstName,
        lastName: employee.lastName,
        isDisplay: true,
        projectsRole: [],
        companyName: company?.companyName || "",
        createdAt: Date.now(),
        license: 'Outsource',
        code: getRandomArbitrary(1000, 9999),
        projectsRole: [
          {
            createdAt: Date.now(),
            role: props.company.id,
            roleId: objid
          }
        ]
      };
  
      const docRef = await addDoc(collection(database, "accounts"), newUser);
      newUser.id = docRef.id;
      addUserToStore(newUser);
  
      toast.success("חשבון העובד התעדכן בהצלחה");
    } catch (error) {
      toast.error(error.message);
    }
  };
  

  const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 10);
  };

  const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  };
  return (
    <div className="card">
      <div className="row">


        <div className="col-lg-4">
          <div className="card-body">
          <div style={{ width: 160, overflow: "hidden", borderRadius: 100 }}>
                  <img src={props.selectedEmployee.avatar} style={{ width: "100%" }}alt="..."/>
                </div>
                <button
                  style={{ marginRight: 20 }} className="btn btn-light"
                  onClick={() => {props.setSelectedEmployee(null)}}><MdOutlineArrowForwardIos size={20} /></button>

            <h3>
              {props.selectedEmployee.firstName}{" "}
              {props.selectedEmployee.lastName}
            </h3>

            {isEditView ? (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="form-label">שם פרטי</label>
                    <input
                      type="text"
                      className="form-control"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">שם משפחה</label>
                    <input
                      type="text"
                      className="form-control"
                      value={lastName}
                      onChange={(e) => {
                        setLastname(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">תעודת זהות</label>
                    <input
                      type="text"
                      className="form-control"
                      value={employeeId}
                      onChange={(e) => {
                        setEmployeeId(e.target.value);
                      }}
                    />
                  </div>


                  <div className="col-lg-6">
                    <label className="form-label">מספר עובד</label>
                    <input
                      type="text"
                      className="form-control"
                      value={employeeNumber}
                      onChange={(e) => {
                        setEmployeeNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">קופת חולים</label>
                    <input
                      type="text"
                      className="form-control"
                      value={hospitality}
                      onChange={(e) => {
                        setHospitality(e.target.value);
                      }}
                    />
                  </div>


                  <div className="col-lg-6">
                    <label className="form-label">תפקיד בחברה</label>
                    <input
                      type="text"
                      className="form-control"
                      value={position}
                      onChange={(e) => {
                        setPosition(e.target.value);
                      }}
                    />
                  </div>




                  <div className="col-lg-6 position-relative">
            <label className="form-label">מחלקה</label>
            <input
              type="text"
              className="form-control"
              value={department}
              onChange={(e) => {
                setDepartment(e.target.value);
              }}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => {
                setTimeout(() => setShowDropdown(false), 100);
              }}
            />
            {showDropdown && (
              <ul className="dropdown-menu show position-absolute w-100" style={{ zIndex: 1000, textAlign:'right' }}>
                {filteredDepartments.map((dept) => (
                  <li
                    key={dept.empId}
                    className="dropdown-item"
                    onClick={() => handleSelectDepartment(dept.departmentName)}
                  >
                    {dept.departmentName}
                  </li>
                ))}
              </ul>
            )}
          </div>
        <div className="col-lg-6">
          <label className="form-label">סניף</label>
          <input
            type="text"
            className="form-control"
            value={branch}
            onChange={(e) => {
              setBranch(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label">קבלן</label>
          <input
            type="text"
            className="form-control"
            value={constructor}
            onChange={(e) => {
              setConstructor(e.target.value);
            }}
          />
        </div>




                  <div className="col-lg-6">
                    <label className="form-label">טלפון נייד</label>
                    <input
                      type="text"
                      className="form-control"
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">דואר אלקטרוני</label>
                    <input
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">כתובת מגורים</label>
                    <input
                      type="text"
                      className="form-control"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">העלאת תמונת פרופיל</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        setAvatar(e.target.files[0]);
                      }}
                    />
                  </div>

                  <div
                    className="col-lg-12"
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      marginTop: 12,
                    }}
                  >
                    <div>
                      <button
                        className="btn btn-light"
                        onClick={() => setIsEditView(!isEditView)}>
                        <MdOutlineArrowForwardIos size={20} />
                      </button>
                    </div>

                    <button
                      className="btn btn-success"
                      onClick={() => {
                        props.updateEmployee(
                          firstName,
                          lastName,
                          address,
                          position,
                          mobile,
                          employeeId,
                          email,
                          avatar,
                          props.selectedEmployee.id,
                          company,
                          branch,
                          constructor,
                          employeeNumber,
                          hospitality,
                          department
                        );
                        setIsEditView(false)}}>שמירה</button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="card-text">
                  <b>תעודת זהות: </b>
                  {props.selectedEmployee.employeeId}
                  <br />
                  <b>תפקיד: </b>
                  {props.selectedEmployee.position}
                  <br />

                  <b>מחלקה: </b>
                  {props.selectedEmployee.department}
                  <br />

                  <b>סניף: </b>
                  {props.selectedEmployee.branch}
                  <br />

                  <b>קבלן: </b>
                  {props.selectedEmployee.constructor}
                  <br />


                  <b>כתובת: </b>
                  {props.selectedEmployee.address}
                  <br />
                  <b>דואר אלקטרוני: </b>
                  {props.selectedEmployee.email}
                  <br />
                  <b>טלפון נייד: </b>
                  {props.selectedEmployee.phone}
                  <br />
                  <b>מסמכים מצורפים: </b>
                  {props.selectedEmployee?.files?.length || 0}
                  <br />
                  <b>הדרכות: </b>
                  {props.selectedEmployee?.trainings?.length || 0}
                  <br />
                  <b>התווסף בתאריך: </b>
                  {moment(props.selectedEmployee.createdAt).format(
                    "DD/MM/YYYY"
                  )}
                  <br />
                </p>
                <button style={{ marginTop: 12 }} className="btn btn-warning" onClick={() => setIsEditView(!isEditView)}>עריכה</button>
                <button style={{ marginTop: 12, marginRight:12 }}  onClick={setAsMemberUser} className='btn btn-success'>הוספת אפשרות התחברות</button>
              </>
            )}

            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "space-between",
              }}
            ></div>
          </div>
        </div>

        <div className="col-lg-4" style={{ paddingTop: 20, paddingBottom: 20 }}>
          <div
            className="col-lg-12"
            style={{
              alignItems: "center",
              display: "flex",
            }}>
            <h3>קבצים </h3>
            <button
              className="btn btn-success btn-sm"
              style={{marginRight:12, marginTop:-4}}
              onClick={() => setAddFileView(!addFileView)}>
              <FaPlus color="#ffffff" size={14} />
            </button>
          </div>
          {addFileView ? (
            <>
              <label className="form-label">שם / תיאור הקובץ</label>
              <input
                type="text"
                className="form-control"
                value={fileName}
                onChange={(e) => {
                  setFileName(e.target.value);
                }}
              />
              <label className="form-label">העלאת קובץ</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  setFileSource(e.target.files[0]);
                }}
              />
              <label className="form-label">תאריך תוקף</label>
              <input
                type="date"
                className="form-control"
                value={fileExpDate}
                onChange={(e) => {
                  setFileExpDate(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  props.uploadEmployeeFile(
                    fileName,
                    fileSource,
                    fileExpDate,
                    props.selectedEmployee.id
                  )
                setAddFileView(!addFileView)
                }
                } style={{ marginTop: 12 }} className="btn btn-success">העלאת קובץ</button>
            </>
          ) : (
            <>
              {props.selectedEmployee.files.length > 0 ? (
                <>
                  {props.selectedEmployee.files.map((file) => (
                    <>
                      <div className="row smallRow">
                        <div className="col-lg-6"><p>{file.fileName}</p></div>
                        <div className="col-lg-2">
                          <a href={file.fileSource} target="_blank" rel="noreferrer">
                            <IoCloudDownloadOutline size={26} color="#D64550" />
                          </a>
                        </div>
                        <div className="col-lg-4">
                          <p>{file.fileExpDate ? moment(file.fileExpDate).format("DD/MM/YYYY") : 'ללא תאריך'}</p>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <p>לא קיימים קבצים מצורפים</p>
              )}
            </>
          )}
        </div>

        <div className="col-lg-4" style={{ paddingTop: 20 }}>
          <h3>הדרכות</h3>
          {trainingsList.length > 0 ? (
            <>
              {trainingsList.map((training) => (
                <div className="row smallRow">
                  <div className="col-lg-12">
                    <p><b>{training.itemName}</b> <div className="badge text-bg-success">{training.itemType}</div><br/>
                    {
                      training.nextItemDate ? (<>בתוקף עד: {moment(training.nextItemDate).format("DD/MM/YYYY")}</>) : (<>התקיים בתאריך: {moment(training.itemDate).format("DD/MM/YYYY")}</>)
                    }
                    
                    </p>
                    
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p>לא קיימות הדרכות מצורפות</p>
          )}



          <h3>וועדות</h3>
          {committeesList.length > 0 ? (
            <>
              {committeesList.map((training) => (
                <div className="row smallRow">
                  <div className="col-lg-12">
                    <p><b>{training.itemName}</b> <div className="badge text-bg-success">{training.itemType}</div><br/>
                    {
                      training.nextItemDate ? (<>בתוקף עד: {moment(training.nextItemDate).format("DD/MM/YYYY")}</>) : (<>התקיים בתאריך: {moment(training.itemDate).format("DD/MM/YYYY")}</>)
                    }
                    
                    </p>
                    
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p>לא קיימות וועדות מצורפות</p>
          )}


        </div>


      </div>
    </div>
  );
};

export default EmployeeCard;
