import { getFirestore, doc, getDoc } from "firebase/firestore";
import moment from "moment";

const db = getFirestore();

export const getLastLogin = async (uuid) => {
  try {
    const userDoc = await getDoc(doc(db, "accounts", uuid));
    if (userDoc.exists()) {
      return moment(userDoc.data().lastLogin).format("DD/MM/YYYY HH:mm");
    }
    return "לא קיים";
  } catch (error) {
    console.error(`Error fetching last login for UUID: ${uuid}`, error);
    return "Unknown";
  }
};
