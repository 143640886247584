import React, { useState, useEffect, useRef } from "react";
import { auth, database, storage } from "../../services/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, query, where, doc, deleteDoc, updateDoc, getDocs } from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import useDashboardStore from "../../store/useDashboardStore";
import {saveLog} from '../../services/createLog'
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import DebriefingItem from "./DebriefingItem";
import DebriefingItemExt from "./DebriefingItemExt";
import { FaRegFilePdf } from "react-icons/fa";


const Debriefing = () => {

  const {
    userPersonalData,
    selectedCompany,
    myCompany,
    companies,
    employees,
    periodics,
    addDebriefingReport,
    debriefingReports,
    updateMachineReport,
    removeDebriefingReportById,
    myPlan,
  } = useDashboardStore();

  const [isLoading, setIsLoading] = useState(false);
  const [reportsArr, setReportsArr] = useState([]);
  const [reportsArrOrig, setReportsArrOrig] = useState([]);   
  const today = new Date();
  const todayMax = new Date().toISOString().split("T")[0];
  const [reportType, setreportType] = useState("");
  const [machines, setMachines] = useState([]);
  const [progress, setProgress] = useState(0);
  const [reportHour, setReportHour] = useState("");
  const fileInputRef = useRef(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportTypeFilter, setReportTypeFilter] = useState("");
  const modalRef = useRef(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [pdfPreviewUrls, setPdfPreviewUrls] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const exportRef = React.useRef();
  const [debriefType, setDebriefType] = useState(null);
  const [reportStatus, setReportStatus] = useState(true);
  const [isReported, setIsReported] = useState(null);
  const [reportDate, setReportDate] = useState(today);
  const [injuredLimb, setInjuredLimb] = useState("");
  const [daysOfAbsence, setDaysOfAbsence] = useState(0);
  const [description, setDescription] = useState("");
  const [personDescription, setPersonDescription] = useState("");
  const [machineDescription, setMachineDescription] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [envDescription, setEnvDescription] = useState("");
  const [proceduresDescription, setProceduresDescription] = useState("");
  const [conclusions, setConclusions] = useState("");
  const [recommendations_p1_desc, setRecommendations_p1_desc] = useState("");
  const [recommendations_p1_person, setRecommendations_p1_person] = useState({});
  const [recommendations_p1_date, setRecommendations_p1_date] = useState(today);
  const [recommendations_p2_desc, setRecommendations_p2_desc] = useState("");
  const [recommendations_p2_person, setRecommendations_p2_person] = useState({});
  const [recommendations_p2_date, setRecommendations_p2_date] = useState(today);
  const [injuredEmployee, setInjuredEmployee] = useState("");
  const [recommendations_p3_desc, setRecommendations_p3_desc] = useState("");
  const [recommendations_p3_person, setRecommendations_p3_person] = useState({});
  const [recommendations_p3_date, setRecommendations_p3_date] = useState(today);
  

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setPdfPreviewUrls(prevFiles => [...prevFiles, ...newFiles]);
    }
  });
  
  const exportToExcel = (reportsArr) => {
    const date = new Date();
    const timestamp = date.getTime();
    const fileName = `Debriefing_${timestamp}.xlsx`;

    const headers = [
        { header: 'רשימת תפוצה', key: 'mailingList', width: 10 },
        { header: 'עדים באירוע', key: 'witnesses', width: 10 },
        { header: 'קבצים בדוח', key: 'reportFiles', width: 10 },
        { header: 'קישור לדווח', key: 'linkToReport', width: 10 },
        { header: 'האם דווח למשרד העבודה', key: 'isReported', width: 10 },
        { header: 'תיאור המשימה', key: 'taskDescription', width: 10 },
        { header: 'תיאור נהלים', key: 'proceduresDescription', width: 10 },
        { header: 'תיאור הסביבה', key: 'envDescription', width: 10 },
        { header: 'תיאור האביזר', key: 'machineDescription', width: 10 },
        { header: 'תיאור האדם', key: 'personDescription', width: 10 },
        { header: 'תיאור התאונה', key: 'description', width: 10 },
        { header: 'ימי היעדרות', key: 'daysOfAbsence', width: 10 },
        { header: 'איבר שנפגע', key: 'injuredLimb', width: 10 },
        { header: 'פרטי הנפגע', key: 'injuredEmployee', width: 10 },
        { header: 'בשעה', key: 'reportHour', width: 15 },
        { header: 'תאריך התאונה', key: 'reportDate', width: 15 },
        { header: 'נוצר בתאריך', key: 'createdAt', width: 15 },
        { header: 'סוג התחקיר', key: 'debriefType', width: 10 },
        { header: 'מספר מזהה', key: 'id', width: 10 },
    ];

    const data = reportsArr.map(report => ({
        id: report.id,
        debriefType: report.debriefType,
        createdAt: moment(report.createdAt).format('DD/MM/YYYY'),
        reportDate: moment(report.reportDate).format('DD/MM/YYYY'),
        reportHour: report.reportHour,
        injuredEmployee: `${report.injuredEmployee.firstName} ${report.injuredEmployee.lastName} תז ${report.injuredEmployee.employeeId} תפקיד ${report.injuredEmployee.position}`,
        injuredLimb: report.injuredLimb,
        daysOfAbsence: report.daysOfAbsence,
        description: report.description,
        personDescription: report.personDescription,
        machineDescription: report.machineDescription,
        envDescription: report.envDescription,
        proceduresDescription: report.proceduresDescription,
        taskDescription: report.taskDescription,
        isReported: report.isReported,
        linkToReport: report.linkToReport,
        reportFiles: report.reportFiles.length,
        witnesses: report.witnesses.length,
        mailingList: report.mailingList.length,
        isActive: report.isActive
    }));
    const ws = XLSX.utils.json_to_sheet(data, { header: headers.map(header => header.key), skipHeader: true });
    for (let i = 0; i < headers.length; i++) {
        ws[XLSX.utils.encode_cell({ c: i, r: 0 })] = { v: headers[i].header };
    }
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reports");
    XLSX.writeFile(wb, fileName);
};

  const updateListByDate = (toDateVal) => {
    setToDate(toDateVal);
    const fromDateTimestamp = new Date(fromDate).getTime();
    const toDateTimestamp = new Date(toDateVal).getTime();

    if (reportTypeFilter !== "") {
      const filteredCommittees = reportsArrOrig.filter(
        (committee) =>
          committee.reportDate >= fromDateTimestamp &&
          committee.reportDate <= toDateTimestamp &&
          committee.reportType === reportTypeFilter
      );
      setReportsArr(filteredCommittees);
    } else {
      const filteredCommittees = reportsArrOrig.filter(
        (committee) =>
          committee.reportDate >= fromDateTimestamp &&
          committee.reportDate <= toDateTimestamp
      );

      setReportsArr(filteredCommittees);
    }
  };
  const applyFilters = () => {
    let filterMachinesReports = reportsArrOrig;

    if (selectedCompany) {
      filterMachinesReports = filterMachinesReports.filter(report => report.associateCompanyId === selectedCompany.id);
    }

    if (debriefType) {
      filterMachinesReports = filterMachinesReports.filter(report => report.debriefType === debriefType);
    }

    if (isReported) {
      filterMachinesReports = filterMachinesReports.filter(report => report.isReported === isReported);
    }


    if (reportStatus !== "") {
        filterMachinesReports = filterMachinesReports.filter(
          (report) => report.isActive === reportStatus
        );
      }

    if (fromDate) {
      const fromTimestamp = new Date(fromDate).getTime();
      filterMachinesReports = filterMachinesReports.filter(report => new Date(report.reportDate).getTime() >= fromTimestamp);
    }
    if (toDate) {
      const toTimestamp = new Date(toDate).getTime();
      filterMachinesReports = filterMachinesReports.filter(report => new Date(report.reportDate).getTime() <= toTimestamp);
    }
    setReportsArr(filterMachinesReports);
  };
  useEffect(() => {
    applyFilters();
  }, [selectedCompany, debriefType, fromDate, toDate, reportsArrOrig,isReported,reportStatus]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const handleHide = () => {
        document.body.classList.remove("modal-open");
        document.querySelector(".modal-backdrop.fade.show")?.remove();
      };
      modalElement.addEventListener("hide.bs.modal", handleHide);
      return () => {
        modalElement.removeEventListener("hide.bs.modal", handleHide);
      };
    }
  }, []);


  
  const addNewReport = async () => {
    if (
        debriefType !== "" && injuredEmployee !== "" && reportDate !== "" && selectedCompany !== "") {

      setIsLoading(true);
  
      try {
        const reportsRef = collection(database, "debriefing_reports");
  
        const uploadTasks = await Promise.all(
          pdfPreviewUrls.map((file) =>
            uploadBytesResumable(ref(storage, `files/${file.name}`), file)
          )
        );
  
        const downloadUrls = await Promise.all(
          uploadTasks.map((task) => getDownloadURL(task.ref))
        );

        const ie = employeesArr.find(x => x.id === injuredEmployee);
  
        const newReport = {
          debriefType:debriefType,
          createdBy: auth.currentUser.uid,
          associateCompanyId: selectedCompany.id,
          associateId: userPersonalData.associateId,
          createdAt: Date.now(),
          reportFiles: downloadUrls,
          isActive: true,
          isReported: isReported,
          injuredEmployee: ie,
          reportDate: new Date(reportDate).getTime(),
          reportHour: reportHour,
          daysOfAbsence: daysOfAbsence,
          injuredLimb: injuredLimb,
          witnesses: selectedEmployees,
          description: description,
          personDescription: personDescription,
          machineDescription: machineDescription,
          envDescription: envDescription,
          taskDescription: taskDescription,
          proceduresDescription: proceduresDescription,
          recommendations_p1: {
            recommendations_p1_date: new Date(recommendations_p1_date).getTime(),
            recommendations_p1_desc,
            recommendations_p1_person
          },
          recommendations_p2: {
            recommendations_p2_date: new Date(recommendations_p2_date).getTime(),
            recommendations_p2_desc,
            recommendations_p2_person
          },
          recommendations_p3: {
            recommendations_p3_date: new Date(recommendations_p3_date).getTime(),
            recommendations_p3_desc,
            recommendations_p3_person
          },
          conclusions: conclusions,
          isReported: isReported,
          linkToReport: linkToReport,
          mailingList: selectedMailingEmployees,
          extraWitnesses: extraWitnesses
        };
  
        // Add the new report to Firestore
        const docRef = await addDoc(reportsRef, newReport);
        newReport.id = docRef.id;
  
        // Add new report to Zustand store
        addDebriefingReport(newReport);

  
        // Log data creation
        const logData = {
          associateCompanyId: selectedCompany.id,
          associateId: userPersonalData.associateId,
          createdBy: userPersonalData.uuid,
          logTitle: "תחקיר חדש",
          logDescription: `${userPersonalData.firstName} ${userPersonalData.lastName} יצר תחקיר חדש עבור ${selectedCompany.companyName}, סוג התחקיר: ${debriefType}`
        };
        saveLog(logData);
  
        // Reset form fields
        resetFormFields();
  
        toast.success("התחקיר התווסף בהצלחה");
      } catch (error) {
        console.error("Error adding report: ", error.message);
        toast.error("ניסיון הוספת תחקיר נכשל");
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("יש למלא את שדות החובה בטופס");
    }
  };





  const resetFormFields = () => {
    setreportType(null);
    setDescription("");
    setReportDate(today);
    setConclusions("");
    setDaysOfAbsence(0);
    setDebriefType(null);
    setInjuredEmployee("")
    setEnvDescription("")
    setInjuredLimb("")
    setPersonDescription("")
    setMachineDescription("")
    setProceduresDescription("")
    setTaskDescription("")
    setRecommendations_p1_date(today);
    setRecommendations_p1_desc("")
    setRecommendations_p1_person("")
    setRecommendations_p2_date(today)
    setRecommendations_p2_desc("")
    setRecommendations_p2_person("")
    setRecommendations_p3_date(today)
    setRecommendations_p3_desc("")
    setRecommendations_p3_person("")
    setSelectedMailingEmployees([])
    setIsReported("")
    setLinkToReport("")
    setSelectedEmployees([])
    setPdfPreviewUrls([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const removeReport = async (report) => {

    const isConfirmed = window.confirm("האם למחוק את התחקיר לצמיתות?");
    if (isConfirmed) {
    try {
      await deleteDoc(doc(database, "debriefing_reports", report.id)).then((res) => {
        removeDebriefingReportById(report.id);
        toast.success("התחקיר הוסר בהצלחה");
      }).catch((err) => {
        toast.error(err.message);
      });
    } catch (error) {
      toast.error(error.message);
    }
  }
  };


  const updateReport = async (
    selectedCategory,
    reportType,
    checkType,
    reportComments,
    reportFile,
    reportDate,
    nextReportDate,
    id
  ) => {
    const updateData = {
      checkType,
      nextReportDate: new Date(nextReportDate).getTime(),
      reportComments,
      reportDate: new Date(reportDate).getTime(),
      reportType,
      department: selectedCategory
    };

    if (reportFile) {
      const sotrageRef = ref(storage, `files/${reportFile.name}`);
      const uploadTask = uploadBytesResumable(sotrageRef, reportFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(prog);
        },
        (error) => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then(async (downloadURL) => {
              const committeesRef = doc(database, "machines_reports", id);
              await updateDoc(committeesRef, {
                ...updateData,
                reportFile: downloadURL
              }).then(() => {
                updateMachineReport(id, { ...updateData, reportFile: downloadURL });
              });
            });
        }
      );
    } else {
      const committeesRef = doc(database, "machines_reports", id);
      await updateDoc(committeesRef, updateData).then(() => {
        updateMachineReport(id, updateData);
      });
    }
  };
  useEffect(() => {
    return () => {
      pdfPreviewUrls.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [pdfPreviewUrls]);


  const handleDownloadPdf = async () => {
    const element = exportRef.current; // Get the element to export
    const canvas = await html2canvas(element, { scale: 1, useCORS: true }); // Generate canvas from DOM
    const imgData = canvas.toDataURL("image/png"); // Convert canvas to data URL

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 190; // A4 width minus some margin
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjusted height based on width
    const pageHeight = pdf.internal.pageSize.height - 20; // A4 height minus some margin for footer

    let heightLeft = imgHeight;
    let position = 0; // Start position for drawing

    // Add the first page with content
    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add subsequent pages if needed
    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft); // Adjust position for next slice
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Add footer on each page
    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8); // Footer font size
      pdf.setTextColor(100); // Footer text color
      pdf.text(
        "This report was generated with Lamemone ERP",
        105,
        pdf.internal.pageSize.height - 10,
        {
          align: "center",
        }
      );
    }

    const timestamp = new Date().getTime();
    pdf.save(`report_${timestamp}.pdf`);
  };


  const handleModal = async () => {
    setShowModal(true); // Open modal to ensure the content is visible for screenshot
  };




  const [employeesArr, setEmployeesArr] = useState([]);
  const [employeesMailingArr, setEmployeesMailingArr] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedMailingEmployees, setSelectedMailingEmployees] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [linkToReport, setLinkToReport] = useState("");
  const [extraWitnesses, setExtraWitnesses] = useState("");

  useEffect(() => {
    const debriefingReportsList = debriefingReports
    .filter(x => x.associateCompanyId === selectedCompany.id)
    .sort((a, b) => a.reportDate - b.reportDate);

    const employeesList = employees
    .filter(x => x.associateCompany === selectedCompany.id)
    .sort((a, b) => {
      const firstNameComparison = a.firstName.localeCompare(b.firstName);
      return firstNameComparison !== 0
        ? firstNameComparison
        : a.lastName.localeCompare(b.lastName);
    });

    setReportsArr(debriefingReportsList);
    setReportsArrOrig(debriefingReportsList);

    setEmployeesArr(employeesList);
    setEmployeesMailingArr(employeesList);
  },[selectedCompany,debriefingReports])


  const handleMailingSelect = (option) => {
    const emp = employeesMailingArr.find((x) => x.id === option);
    if (!selectedMailingEmployees.includes(option)) {
      setSelectedMailingEmployees([...selectedMailingEmployees, emp]);
      setEmployeesMailingArr(employeesMailingArr.filter((x) => x.id !== option));
    }
    setSelectedOption("");
  };
  const handleMailingRemove = (option) => {
    const updatedValues = selectedMailingEmployees.filter((v) => v !== option);
    setSelectedMailingEmployees(updatedValues);
    if (!employeesMailingArr.includes(option)) {
      if (!employeesMailingArr.some((employee) => employee.id === option.id)) {
        setEmployeesMailingArr([...employeesMailingArr, option]);
      }
    }
  };






  const handleSelect = (option) => {
    const emp = employeesArr.find((x) => x.id === option);
    if (!selectedEmployees.includes(option)) {
      setSelectedEmployees([...selectedEmployees, emp]);
      setEmployeesArr(employeesArr.filter((x) => x.id !== option));
    }
    setSelectedOption("");
  };
  const handleRemove = (option) => {
    const updatedValues = selectedEmployees.filter((v) => v !== option);
    setSelectedEmployees(updatedValues);
    if (!employeesArr.includes(option)) {
      if (!employeesArr.some((employee) => employee.id === option.id)) {
        setEmployeesArr([...employeesArr, option]);
      }
    }
  };




  return (
    <>
      <ToastContainer />
      
      <div ref={modalRef} className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">הוספת תחקיר חדש</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div className="modal-body">
              
              
              
              
              
              <div className="row">


                <div className="col-lg-6">
                  <label className="form-label">סוג התחקיר <span style={{ color: "red" }}>*</span></label>
                     <select value={debriefType} onChange={(e) => setDebriefType(e.target.value)} className="form-control">
                        <option value="">בחר מהרשימה...</option>
                        <option value="תאונת עבודה">תאונת עבודה</option>
                        <option value="כמעט ונפגע">כמעט ונפגע</option>
                    </select>
                </div>

                <div className="col-lg-6">
                  <label className="form-label">שם העובד <span style={{ color: "red" }}>*</span></label>
                  <select
                      value={injuredEmployee}
                      onChange={(e) => setInjuredEmployee(e.target.value)} 
                      className="form-control">
                      <option value="">בחר את שם העובד הנפגע...</option>
                      {employeesArr.length > 0 && (
                        <>
                          {employeesArr.map((emp) => (
                            <option key={emp.id} value={emp.id}>
                              {emp.firstName} {emp.lastName}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label">תאריך התאונה <span style={{ color: "red" }}>*</span></label>
                  <input max={todayMax} type="date"
                    className="form-control"
                    value={moment(reportDate).format("YYYY-MM-DD")}
                    onChange={(e) => {setReportDate(e.target.value);}}/>
                </div>
                <div className="col-lg-6">
                  <label className="form-label">שעת התאונה</label>
                  <input
                    type="time"
                    className="form-control"
                    value={reportHour}
                    onChange={(e) => {setReportHour(e.target.value);}}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                    <label className="form-label">איבר שנפגע</label>
                    <input type="text" className="form-control" value={injuredLimb} onChange={(e) => {setInjuredLimb(e.target.value)}} />
                </div>
                <div className="col-lg-6">
                    <label className="form-label">ימי היעדרות</label>
                    <input type="text" className="form-control" value={daysOfAbsence} onChange={(e) => {setDaysOfAbsence(e.target.value)}} />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                    <label className="form-label">עדים למקרה</label>
                    <select className="form-control"  onChange={(e) => {handleSelect(e.target.value);}}>
                <option value="0">בחר עובדים מהרשימה...</option>
                {employeesArr.length > 0 && (
                  <>
                    {employeesArr.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.lastName} {employee.firstName} {employee.employeeId}
                      </option>
                    ))}
                  </>
                )}
              </select>
              {selectedEmployees.length > 0 && (
                <>
                  {selectedEmployees.map((emp) => (
                    <>
                      <span key={emp.empId} onClick={() => { handleRemove(emp);}} style={{ marginLeft: 6 }} className="badge text-bg-info">{emp.firstName} {emp.lastName}</span>
                    </>
                  ))}
                </>
              )}


                </div>
              </div>

              <label className="form-label">עדים נוספים</label>
              <textarea
                className="form-control"
                value={extraWitnesses}
                onChange={(e) => {setExtraWitnesses(e.target.value);}}></textarea>


              <label className="form-label">תיאור התחקיר</label>
              <textarea
                className="form-control"
                value={description}
                onChange={(e) => {setDescription(e.target.value);}}></textarea>


            <label className="form-label">אדם: פרוט דברים שהאדם ביצע גרמו לתאונה, כולל שמות.
            ציוד מגן אישי, הדרכה, הסמכה, ותק.</label>
              <textarea
                className="form-control"
                value={personDescription}
                onChange={(e) => {setPersonDescription(e.target.value);}}></textarea>

            <label className="form-label">מכונה: פרוט מכונות או חלקי מכונה שהיו מעורבות בתאונה, האם המכונה נבדקה, פורק או היה חסר מיגון וכו'</label>
              <textarea
                className="form-control"
                value={machineDescription}
                onChange={(e) => {setMachineDescription(e.target.value);}}></textarea>

            <label className="form-label">משימה: איזו משימה ביצע העובד בעת התאונה, ואיזו היה צריך לבצע.</label>
              <textarea
                className="form-control"
                value={taskDescription}
                onChange={(e) => {setTaskDescription(e.target.value);}}></textarea>

            <label className="form-label">תנאי סביבה: ארגונומיה, סדר וניקיון, צפיפות, מפגעים וסיכונים, ועוד</label>
              <textarea
                className="form-control"
                value={envDescription}
                onChange={(e) => {setEnvDescription(e.target.value);}}></textarea>

            <label className="form-label">נהלים:
            האם ישנם נהלים, הוראות עבודה, הדרכה, טפסים למילוי, עבודה בניגוד לנהלים.</label>
              <textarea
                className="form-control"
                value={proceduresDescription}
                onChange={(e) => {setProceduresDescription(e.target.value);}}></textarea>

            <label className="form-label">סיכום ומסקנות</label>
              <textarea
                className="form-control"
                value={conclusions}
                onChange={(e) => {setConclusions(e.target.value);}}></textarea>


            <label className="form-label">המלצות לתיקון / שיפור – פעולה מתקנת או מונעת</label>
            <div className="row">
                <div className="col-lg-6">
                    <input type="text" className="form-control" value={recommendations_p1_desc} onChange={(e) => {setRecommendations_p1_desc(e.target.value)}} />
                </div>
                <div className="col-lg-3">
                <select
                      value={recommendations_p1_person}
                      onChange={(e) => setRecommendations_p1_person(e.target.value)}
                      className="form-control"
                    >
                      <option value="">בחר אחראי למעקב...</option>
                      {employeesArr.length > 0 && (
                        <>
                          {employeesArr.map((emp) => (
                            <option key={emp.id} value={emp.id}>
                              {emp.firstName} {emp.lastName}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                </div>
                <div className="col-lg-3">
                <input
                    type="date"
                    className="form-control"
                    value={moment(recommendations_p1_date).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setRecommendations_p1_date(e.target.value);
                    }}
                  />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <input type="text" className="form-control" value={recommendations_p2_desc} onChange={(e) => {setRecommendations_p2_desc(e.target.value)}} />
                </div>
                <div className="col-lg-3">
                <select
                      value={recommendations_p2_person}
                      onChange={(e) => setRecommendations_p2_person(e.target.value)}
                      className="form-control"
                    >
                      <option value="">בחר אחראי למעקב...</option>
                      {employeesArr.length > 0 && (
                        <>
                          {employeesArr.map((emp) => (
                            <option key={emp.id} value={emp.id}>
                              {emp.firstName} {emp.lastName}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                </div>
                <div className="col-lg-3">
                <input
                    type="date"
                    className="form-control"
                    value={moment(recommendations_p2_date).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setRecommendations_p2_date(e.target.value);
                    }}
                  />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <input type="text" className="form-control" value={recommendations_p3_desc} onChange={(e) => {setRecommendations_p3_desc(e.target.value)}} />
                </div>
                <div className="col-lg-3">
                <select
                      value={recommendations_p3_person}
                      onChange={(e) => setRecommendations_p3_person(e.target.value)}
                      className="form-control"
                    >
                      <option value="">בחר אחראי למעקב...</option>
                      {employeesArr.length > 0 && (
                        <>
                          {employeesArr.map((emp) => (
                            <option key={emp.id} value={emp.id}>
                              {emp.firstName} {emp.lastName}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                </div>
                <div className="col-lg-3">
                <input
                    type="date"
                    className="form-control"
                    value={moment(recommendations_p3_date).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setRecommendations_p3_date(e.target.value);
                    }}
                  />
                </div>
            </div>



              <label className="form-label">העלאת קבצים</label>
              <div {...getRootProps({ className: 'dropzone', style: dropzoneStyle })}>
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>ניתן לגרור קבצים לכאן או להקליק כאן על מנת לבחור קבצים ידנית</p>
                }
              </div>

              <div style={thumbsContainer}>
                {pdfPreviewUrls.map((file, index) => (
                  <div style={thumb} key={index}>
                    <div style={thumbInner}>
                      <iframe
                        src={file.preview}
                        style={img}
                        frameBorder="0"
                        title={`PDF Preview ${index + 1}`}
                      ></iframe>
                    </div>
                  </div>
                ))}
              </div>


                <div className="row">
                    <div className="col-lg-4">
                        <label className="form-label">דווח למשרד העבודה</label>
                        <select value={isReported} onChange={(e) => setIsReported(e.target.value)} className="form-control">
                            <option value="">בחר מהרשימה...</option>
                            <option value="דווח">דווח</option>
                            <option value="לא דווח">לא דווח</option>
                        </select>
                    </div>
                    <div className="col-lg-8">
                    <label className="form-label">קישור לדוח</label>
                    <input type="text" className="form-control" value={linkToReport} onChange={(e) => {setLinkToReport(e.target.value)}} />
                    </div>
                </div>

              <div className="row">
                <div className="col-lg-12">
                    <label className="form-label">רשימת תפוצה (לא כולל אחראים לטיפול)</label>
                    <select className="form-control"  onChange={(e) => {handleMailingSelect(e.target.value);}}>
                <option value="0">בחר עובדים מהרשימה...</option>
                {employeesMailingArr.length > 0 && (
                  <>
                    {employeesMailingArr.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.lastName} {employee.firstName} {employee.employeeId}
                      </option>
                    ))}
                  </>
                )}
              </select>
              {selectedMailingEmployees.length > 0 && (
                <>
                  {selectedMailingEmployees.map((emp) => (
                    <>
                      <span key={emp.empId} onClick={() => { handleMailingRemove(emp);}} style={{ marginLeft: 6 }} className="badge text-bg-info">{emp.firstName} {emp.lastName}</span>
                    </>
                  ))}
                </>
              )}


                </div>
              </div>

            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">סגירה</button>
              <button onClick={addNewReport} type="button" className="btn btn-success">הוספת תחקיר חדש</button>
            </div>

          </div>
        </div>
      </div>

      <div className="row heading-container">
        
        <div className="col-lg-9"><h3>תחקיר תאונת עבודה / כמעט ונפגע | {selectedCompany.companyName}</h3></div>
        <div className="col-lg-3 heading-btn-container">
          <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-success btn-lg">הוספת תחקיר חדש</button>
          {
            myPlan.actions && <>
            <button style={{marginRight:10}} onClick={handleModal} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
            <button className="btn btn-success btn-lg" style={{marginRight:10}} onClick={() => {exportToExcel(reportsArr)}}><PiMicrosoftExcelLogoFill color="#ffffff" size={30} /></button>
            </>
          }
          
        </div>
      </div>


      <div className="row heading-container-filter">
        <div className="col-lg-2">
          <label className="form-label">סוג התחקיר</label>
          <select value={debriefType} onChange={(e) => setDebriefType(e.target.value)} className="form-control">
            <option value="">בחר מהרשימה...</option>
            <option value="תאונת עבודה">תאונת עבודה</option>
            <option value="כמעט ונפגע">כמעט ונפגע</option>
        </select>
        </div>


        <div className="col-lg-2">
          <label className="form-label">סטטוס</label>
          <select
  value={reportStatus}
  onChange={(e) => setReportStatus(e.target.value === "true" ? true : e.target.value === "false" ? false : "")}
  className="form-control"
>
  <option value="">בחר מהרשימה...</option>
  <option value={true}>פעיל</option>
  <option value={false}>לא פעיל</option>
</select>
        </div>



        <div className="col-lg-2">
          <label className="form-label">דווח למשרד העבודה</label>
          <select value={isReported} onChange={(e) => setIsReported(e.target.value)} className="form-control">
            <option value="">בחר מהרשימה...</option>
            <option value="דווח">דווח</option>
            <option value="לא דווח">לא דווח</option>
        </select>
        </div>



        <div className="col-lg-2">
          <label className="form-label">מתאריך</label>
          <input
            type="date"
            className="form-control"
            value={moment(fromDate).format("YYYY-MM-DD")}
            onChange={(e) => {setFromDate(e.target.value);}}/>
        </div>
        <div className="col-lg-2">
          <label className="form-label">עד תאריך</label>
          <input
            type="date"
            className="form-control"
            value={moment(toDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              updateListByDate(e.target.value);
            }}
          />
        </div>

        <div className="col-lg-2"></div>
      </div>



      <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 14 }}>


      
          {reportsArr.length > 0 && (
            <>
            
              {reportsArr.map((report) => (
                <div className="row" style={{ padding: 20, marginBottom:12, background:'#f8f8f8', borderRadius:10 }}>
                <DebriefingItem
                  key={report.id}
                  updateReport={updateReport}
                  removeReport={removeReport}
                  report={report}
                />
                </div>
              ))}
            </>
          )}




    </div>


      <Modal
        show={showModal}
        dialogClassName="custom-modal"
        onHide={() => setShowModal(false)}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" ref={exportRef} style={{ padding: 50 }}>
            
            <div className="row">
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-2">
                    <img src="/images/logo_black.png" style={{ width: 60 }} />
                  </div>
                  <div className="col-lg-10">
                    <p className="companyInfo">
                      דוח בטיחות זה
                      <br />
                      הופק באמצעות
                      <br />
                      מערכת לממונה
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                {myCompany && myCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={myCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{myCompany.companyName}</b>
                          <br />
                          {myCompany.companyAddress}, {myCompany.companyCity}
                          <br />
                          ח.פ. {myCompany.companyTaxId}
                          <br />
                          {myCompany.companyEmail} | {myCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-lg-4">
                {selectedCompany && selectedCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={selectedCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{selectedCompany.companyName}</b>
                          <br />
                          {selectedCompany.companyAddress},{" "}
                          {selectedCompany.companyCity}
                          <br />
                          ח.פ. {selectedCompany.companyTaxId}
                          <br />
                          {selectedCompany.companyEmail} |{" "}
                          {selectedCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 50, marginBottom:50 }}>
              <div className="col-lg-12">
                <h2>תחקירי תאונות עבודה | {selectedCompany.companyName}</h2>
              </div>
            </div>

            {reportsArr.length > 0 && (
            <>
              {reportsArr.map((report) => (
                <div className="row" style={{ padding: 20, marginBottom:12, background:'#f8f8f8', borderRadius:10 }}>
                <DebriefingItemExt
                  key={report.id}
                  report={report}
                />
                </div>
              ))}
            </>
          )}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleDownloadPdf}
            className="btn btn-success btn-lg">שמירת קובץ</button>
        </Modal.Footer>
      </Modal>




    </>
  );
};



const dropzoneStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginBottom: '20px'
};
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};
const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: '100%',
  height: 200,
  padding: 4,
  boxSizing: 'border-box'
};
const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  width:'100%'
};
const img = {
  display: 'block',
  width: '100%',
  height: '100%'
};



export default Debriefing;
