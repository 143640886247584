import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useDropzone } from 'react-dropzone';


const DebriefingForm = (props) => {

    const today = new Date();
    const todayMax = new Date().toISOString().split("T")[0];
    const [report, setReport] = useState(props.report);
    const [employeesArr, setEmployeesArr] = useState(props.employeesArr);
    const [debriefType, setDebriefType] = useState(props.report.debriefType);
    const [isReported, setIsReported] = useState(props.report.isReported);
    const [reportDate, setReportDate] = useState(props.report.reportDate);
    const [injuredLimb, setInjuredLimb] = useState(props.report.injuredLimb);
    const [daysOfAbsence, setDaysOfAbsence] = useState(props.report.daysOfAbsence);
    const [description, setDescription] = useState(props.report.description);
    const [personDescription, setPersonDescription] = useState(props.report.personDescription);
    const [machineDescription, setMachineDescription] = useState(props.report.machineDescription);
    const [taskDescription, setTaskDescription] = useState(props.report.taskDescription);
    const [envDescription, setEnvDescription] = useState(props.report.envDescription);
    const [proceduresDescription, setProceduresDescription] = useState(props.report.proceduresDescription);
    const [conclusions, setConclusions] = useState(props.report.conclusions);
    const [recommendations_p1_desc, setRecommendations_p1_desc] = useState(props.report.recommendations_p1.recommendations_p1_desc);
    const [recommendations_p1_person, setRecommendations_p1_person] = useState(props.report.recommendations_p1.recommendations_p1_person);
    const [recommendations_p1_date, setRecommendations_p1_date] = useState(props.report.recommendations_p1.recommendations_p1_date);
    const [recommendations_p2_desc, setRecommendations_p2_desc] = useState(props.report.recommendations_p2.recommendations_p2_desc);
    const [recommendations_p2_person, setRecommendations_p2_person] = useState(props.report.recommendations_p2.recommendations_p2_person);
    const [recommendations_p2_date, setRecommendations_p2_date] = useState(props.report.recommendations_p2.recommendations_p2_date);
    const [injuredEmployee, setInjuredEmployee] = useState(props.report.injuredEmployee.id);
    const [recommendations_p3_desc, setRecommendations_p3_desc] = useState(props.report.recommendations_p3.recommendations_p3_desc);
    const [recommendations_p3_person, setRecommendations_p3_person] = useState(props.report.recommendations_p3.recommendations_p3_person);
    const [recommendations_p3_date, setRecommendations_p3_date] = useState(props.report.recommendations_p3.recommendations_p3_date);
    const [linkToReport, setLinkToReport] = useState(props.report.linkToReport);
    const [extraWitnesses, setExtraWitnesses] = useState(props.report.extraWitnesses);
    const [reportHour, setReportHour] = useState(props.report.reportHour);


    const [selectedEmployees, setSelectedEmployees] = useState(props.report.witnesses);
    const [selectedMailingEmployees, setSelectedMailingEmployees] = useState(props.report.mailingList);
    const [selectedOption, setSelectedOption] = useState("");
    const [employeesMailingArr, setEmployeesMailingArr] = useState([]);
    const [debriefingReports, setDebriefingReports] = useState(props.debriefingReports);
    const [selectedCompany, setSelectedCompany] = useState(props.selectedCompany);
    const [pdfPreviewUrls, setPdfPreviewUrls] = useState([]);
    const [employees, setEmployees] = useState(props.employees);
    const [reportsArr, setReportsArr] = useState([]);
    const [reportsArrOrig, setReportsArrOrig] = useState([]); 


    useEffect(() => {
        const debriefingReportsList = debriefingReports
        .filter(x => x.associateCompanyId === selectedCompany.id)
        .sort((a, b) => a.reportDate - b.reportDate);
    
        const employeesList = employees
        .filter(x => x.associateCompany === selectedCompany.id)
        .sort((a, b) => {
          const firstNameComparison = a.firstName.localeCompare(b.firstName);
          return firstNameComparison !== 0
            ? firstNameComparison
            : a.lastName.localeCompare(b.lastName);
        });
    
        setReportsArr(debriefingReportsList);
        setReportsArrOrig(debriefingReportsList);
    
        setEmployeesArr(employeesList);
        setEmployeesMailingArr(employeesList);
      },[selectedCompany,debriefingReports])


    const handleSelect = (option) => {
        const emp = employeesArr.find((x) => x.id === option);
        if (!selectedEmployees.includes(option)) {
          setSelectedEmployees([...selectedEmployees, emp]);
          setEmployeesArr(employeesArr.filter((x) => x.id !== option));
        }
        setSelectedOption("");
      };
      const handleRemove = (option) => {
        const updatedValues = selectedEmployees.filter((v) => v !== option);
        setSelectedEmployees(updatedValues);
        if (!employeesArr.includes(option)) {
          if (!employeesArr.some((employee) => employee.id === option.id)) {
            setEmployeesArr([...employeesArr, option]);
          }
        }
      };
      const handleMailingSelect = (option) => {
        const emp = employeesMailingArr.find((x) => x.id === option);
        if (!selectedMailingEmployees.includes(option)) {
          setSelectedMailingEmployees([...selectedMailingEmployees, emp]);
          setEmployeesMailingArr(employeesMailingArr.filter((x) => x.id !== option));
        }
        setSelectedOption("");
      };
      const handleMailingRemove = (option) => {
        const updatedValues = selectedMailingEmployees.filter((v) => v !== option);
        setSelectedMailingEmployees(updatedValues);
        if (!employeesMailingArr.includes(option)) {
          if (!employeesMailingArr.some((employee) => employee.id === option.id)) {
            setEmployeesMailingArr([...employeesMailingArr, option]);
          }
        }
      };

      const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'application/pdf',
        onDrop: acceptedFiles => {
          const newFiles = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          }));
          setPdfPreviewUrls(prevFiles => [...prevFiles, ...newFiles]);
        }
      });


      const updateDebrief = async() => {

        props.updateDebriefAction(
            props.report.id,
            debriefType,
            isReported,
            reportDate,
            injuredLimb,
            daysOfAbsence,
            description,
            personDescription,
            machineDescription,
            taskDescription,
            envDescription,
            proceduresDescription,
            conclusions,
            recommendations_p1_desc,
            recommendations_p2_desc,
            recommendations_p3_desc,
            recommendations_p1_person,
            recommendations_p2_person,
            recommendations_p3_person,
            recommendations_p1_date,
            recommendations_p2_date,
            recommendations_p3_date,
            injuredEmployee,
            linkToReport,
            extraWitnesses,
            reportHour,
            selectedEmployees,
            selectedMailingEmployees,
            pdfPreviewUrls
        );

      }

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <label className="form-label">
            סוג התחקיר <span style={{ color: "red" }}>*</span>
          </label>
          <select
            value={debriefType}
            onChange={(e) => setDebriefType(e.target.value)}
            className="form-control"
          >
            <option
              selected={debriefType === "תאונת עבודה" ? true : false}
              value="תאונת עבודה"
            >
              תאונת עבודה
            </option>
            <option
              selected={debriefType === "כמעט ונפגע" ? true : false}
              value="כמעט ונפגע"
            >
              כמעט ונפגע
            </option>
          </select>
        </div>

        <div className="col-lg-6">
          <label className="form-label">
            שם העובד <span style={{ color: "red" }}>*</span>
          </label>
          <select
            value={injuredEmployee}
            onChange={(e) => setInjuredEmployee(e.target.value)}
            className="form-control"
          >
            {employeesArr.length > 0 && (
              <>
                {employeesArr.map((emp) => (
                  <option
                    selected={
                      injuredEmployee.id === emp.id ? true : false
                    }
                    key={emp.id}
                    value={emp.id}
                  >
                    {emp.firstName} {emp.lastName}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <label className="form-label">
            תאריך התאונה <span style={{ color: "red" }}>*</span>
          </label>
          <input
            max={todayMax}
            type="date"
            className="form-control"
            value={moment(reportDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              setReportDate(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label">שעת התאונה</label>
          <input
            type="time"
            className="form-control"
            value={reportHour}
            onChange={(e) => {
              setReportHour(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <label className="form-label">איבר שנפגע</label>
          <input
            type="text"
            className="form-control"
            value={injuredLimb}
            onChange={(e) => {
              setInjuredLimb(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <label className="form-label">ימי היעדרות</label>
          <input
            type="text"
            className="form-control"
            value={daysOfAbsence}
            onChange={(e) => {
              setDaysOfAbsence(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <label className="form-label">עדים למקרה</label>
          <select
            className="form-control"
            onChange={(e) => {
              handleSelect(e.target.value);
            }}
          >
            <option value="0">בחר עובדים מהרשימה...</option>
            {employeesArr.length > 0 && (
              <>
                {employeesArr.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.lastName} {employee.firstName}{" "}
                    {employee.employeeId}
                  </option>
                ))}
              </>
            )}
          </select>
          {selectedEmployees.length > 0 && (
            <>
              {selectedEmployees.map((emp) => (
                <>
                  <span
                    key={emp.empId}
                    onClick={() => {
                      handleRemove(emp);
                    }}
                    style={{ marginLeft: 6 }}
                    className="badge text-bg-info"
                  >
                    {emp.firstName} {emp.lastName}
                  </span>
                </>
              ))}
            </>
          )}
        </div>
      </div>

      <label className="form-label">עדים נוספים</label>
      <textarea
        className="form-control"
        value={extraWitnesses}
        onChange={(e) => {
          setExtraWitnesses(e.target.value);
        }}
      ></textarea>

      <label className="form-label">תיאור התחקיר</label>
      <textarea
        className="form-control"
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      ></textarea>

      <label className="form-label">
        אדם: פרוט דברים שהאדם ביצע גרמו לתאונה, כולל שמות. ציוד מגן אישי, הדרכה,
        הסמכה, ותק.
      </label>
      <textarea
        className="form-control"
        value={personDescription}
        onChange={(e) => {
          setPersonDescription(e.target.value);
        }}
      ></textarea>

      <label className="form-label">
        מכונה: פרוט מכונות או חלקי מכונה שהיו מעורבות בתאונה, האם המכונה נבדקה,
        פורק או היה חסר מיגון וכו'
      </label>
      <textarea
        className="form-control"
        value={machineDescription}
        onChange={(e) => {
          setMachineDescription(e.target.value);
        }}
      ></textarea>

      <label className="form-label">
        משימה: איזו משימה ביצע העובד בעת התאונה, ואיזו היה צריך לבצע.
      </label>
      <textarea
        className="form-control"
        value={taskDescription}
        onChange={(e) => {
          setTaskDescription(e.target.value);
        }}
      ></textarea>

      <label className="form-label">
        תנאי סביבה: ארגונומיה, סדר וניקיון, צפיפות, מפגעים וסיכונים, ועוד
      </label>
      <textarea
        className="form-control"
        value={envDescription}
        onChange={(e) => {
          setEnvDescription(e.target.value);
        }}
      ></textarea>

      <label className="form-label">
        נהלים: האם ישנם נהלים, הוראות עבודה, הדרכה, טפסים למילוי, עבודה בניגוד
        לנהלים.
      </label>
      <textarea
        className="form-control"
        value={proceduresDescription}
        onChange={(e) => {
          setProceduresDescription(e.target.value);
        }}
      ></textarea>

      <label className="form-label">סיכום ומסקנות</label>
      <textarea
        className="form-control"
        value={conclusions}
        onChange={(e) => {
          setConclusions(e.target.value);
        }}
      ></textarea>

      <label className="form-label">
        המלצות לתיקון / שיפור – פעולה מתקנת או מונעת
      </label>
      <div className="row">
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control"
            value={recommendations_p1_desc}
            onChange={(e) => {
              setRecommendations_p1_desc(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-3">
          <select
            value={recommendations_p1_person}
            onChange={(e) => setRecommendations_p1_person(e.target.value)}
            className="form-control"
          >
            <option value="">בחר אחראי למעקב...</option>
            {employeesArr.length > 0 && (
              <>
                {employeesArr.map((emp) => (
                  <option selected={recommendations_p1_person === emp.id? true : false} key={emp.id} value={emp.id}>
                    {emp.firstName} {emp.lastName}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        <div className="col-lg-3">
          <input
            type="date"
            className="form-control"
            value={moment(recommendations_p1_date).format("YYYY-MM-DD")}
            onChange={(e) => {
              setRecommendations_p1_date(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control"
            value={recommendations_p2_desc}
            onChange={(e) => {
              setRecommendations_p2_desc(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-3">
          <select
            value={recommendations_p2_person}
            onChange={(e) => setRecommendations_p2_person(e.target.value)}
            className="form-control"
          >
            <option value="">בחר אחראי למעקב...</option>
            {employeesArr.length > 0 && (
              <>
                {employeesArr.map((emp) => (
                  <option selected={recommendations_p2_person === emp.id? true : false} key={emp.id} value={emp.id}>
                    {emp.firstName} {emp.lastName}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        <div className="col-lg-3">
          <input
            type="date"
            className="form-control"
            value={moment(recommendations_p2_date).format("YYYY-MM-DD")}
            onChange={(e) => {
              setRecommendations_p2_date(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <input
            type="text"
            className="form-control"
            value={recommendations_p3_desc}
            onChange={(e) => {
              setRecommendations_p3_desc(e.target.value);
            }}
          />
        </div>
        <div className="col-lg-3">
          <select
            value={recommendations_p3_person}
            onChange={(e) => setRecommendations_p3_person(e.target.value)}
            className="form-control"
          >
            <option value="">בחר אחראי למעקב...</option>
            {employeesArr.length > 0 && (
              <>
                {employeesArr.map((emp) => (
                  <option selected={recommendations_p3_person === emp.id? true : false} key={emp.id} value={emp.id}>
                    {emp.firstName} {emp.lastName}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        <div className="col-lg-3">
          <input
            type="date"
            className="form-control"
            value={moment(recommendations_p3_date).format("YYYY-MM-DD")}
            onChange={(e) => {
              setRecommendations_p3_date(e.target.value);
            }}
          />
        </div>
      </div>

      <label className="form-label">העלאת קבצים</label>
      <div {...getRootProps({ className: "dropzone", style: dropzoneStyle })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>ניתן לגרור קבצים לכאן או להקליק כאן על מנת לבחור קבצים ידנית</p>
        )}
      </div>

      <div style={thumbsContainer}>
        {pdfPreviewUrls.map((file, index) => (
          <div style={thumb} key={index}>
            <div style={thumbInner}>
              <iframe
                src={file.preview}
                style={img}
                frameBorder="0"
                title={`PDF Preview ${index + 1}`}
              ></iframe>
            </div>
          </div>
        ))}
      </div>

      <div className="row">
        <div className="col-lg-4">
          <label className="form-label">דווח למשרד העבודה</label>
          <select
            value={isReported}
            onChange={(e) => setIsReported(e.target.value)}
            className="form-control">
            <option selected={isReported === "דווח" ? true : false} value="דווח">דווח</option>
            <option selected={isReported === "לא דווח" ? true : false} value="לא דווח">לא דווח</option>
          </select>
        </div>
        <div className="col-lg-8">
          <label className="form-label">קישור לדוח</label>
          <input
            type="text"
            className="form-control"
            value={linkToReport}
            onChange={(e) => {
              setLinkToReport(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <label className="form-label">
            רשימת תפוצה (לא כולל אחראים לטיפול)
          </label>
          <select
            className="form-control"
            onChange={(e) => {
              handleMailingSelect(e.target.value);
            }}
          >
            <option value="0">בחר עובדים מהרשימה...</option>
            {employeesMailingArr.length > 0 && (
              <>
                {employeesMailingArr.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.lastName} {employee.firstName}{" "}
                    {employee.employeeId}
                  </option>
                ))}
              </>
            )}
          </select>
          {selectedMailingEmployees.length > 0 && (
            <>
              {selectedMailingEmployees.map((emp) => (
                <>
                  <span
                    key={emp.empId}
                    onClick={() => {
                      handleMailingRemove(emp);
                    }}
                    style={{ marginLeft: 6 }}
                    className="badge text-bg-info"
                  >
                    {emp.firstName} {emp.lastName}
                  </span>
                </>
              ))}
            </>
          )}
        </div>
      </div>
        <br/><br/>
      <button onClick={updateDebrief} type="button" className="btn btn-success">עדכון תחקיר</button>
      <br/><br/>
    </>
  );
};


const dropzoneStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: '20px'
  };
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: '100%',
    height: 200,
    padding: 4,
    boxSizing: 'border-box'
  };
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    width:'100%'
  };
  const img = {
    display: 'block',
    width: '100%',
    height: '100%'
  };
export default DebriefingForm;
