import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  IoSettingsSharp,
  IoPencilSharp,
} from "react-icons/io5";
import { storage } from "../../../services/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { IoMdCloseCircle } from "react-icons/io";


const FindingItemExp = (props) => {

  const [downloadURLs, setDownloadURLs] = useState(props.item.gallery);
  const [inputKey, setInputKey] = useState(Date.now());
  const [files, setFiles] = useState([]);
  const [itemImpairmentSeverity, setItemImpairmentSeverity] = useState(props.item.itemImpairmentSeverity);
  const [itemImpairmentArea, setItemImpairmentArea] = useState(props.item.itemImpairmentArea);
  const [itemImpairmentDesc, setItemImpairmentDesc] = useState(props.item.itemImpairmentDesc);
  const [itemImpairmentComments, setItemImpairmentComments] = useState(props.item.itemImpairmentComments);
  const [itemImpairmentLocation, setItemImpairmentLocation] = useState(props.item.itemImpairmentLocation);
  const [itemImpairmentTreat, setItemImpairmentTreat] = useState(props.item.itemImpairmentTreat);
  const [itemImpairmentTrack, setItemImpairmentTrack] = useState(props.item.itemImpairmentTrack);
  const [itemImpairmentImgReq, setItemImpairmentImgReq] = useState(props.item.itemImpairmentImgReq);
  const [itemImpairmentCommReq, setItemImpairmentCommReq] = useState(props.item.itemImpairmentCommReq);
  const project = props?.projects?.find((x) => x.siteId === props.item.site);
  const findingType = props.findingsType.find((x) => x.id === props.item.itemImpairmentArea);
  const company = props.companiesArr.find((x) => x.id === props.item.associateCompanyId);
  const treater = props.employeesArr.find((x) => x.id === props.item.itemImpairmentTreat);
  const tracker = props.employeesArr.find((x) => x.id === props.item.itemImpairmentTrack);
  const [isEditView, setIsEditView] = useState(false);
  const [isTreatEdit, setIsTreatEdit] = useState(false);
  const [targetDate, setTargetDate] = useState(props.item.targetDate);

  let priorityColor = "#ffcc00";
  let statusColor = "#ffcc00"

  if (props.item.itemImpairmentSeverity === "קל") {
    priorityColor = "#6fcb9f";
  }
  if (props.item.itemImpairmentSeverity === "בינוני") {
    priorityColor = "#ffcc00";
  }
  if (
    props.item.itemImpairmentSeverity === "חמור" ||
    props.item.itemImpairmentSeverity === "מסכן חיים"
  ) {
    priorityColor = "#EB6079";
  }

  const uploadFiles = (filesToUpload) => {
    Array.from(filesToUpload).forEach((file) => {
      const storageRef = ref(storage, `findings/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);

            const newImage = {
              imageSource: downloadURL,
              imgId: `${Date.now()}`
            };

            setDownloadURLs((prevURLs) => [...prevURLs, newImage]);

            if (file === filesToUpload[filesToUpload.length - 1]) {
              setInputKey(Date.now());
            }
          });
        }
      );
    });
  };
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles); // Set the files in state
    uploadFiles(selectedFiles); // Call uploadFiles immediately
  };
  const uploadTreatFiles = (filesToUpload) => {
    Array.from(filesToUpload).forEach((file) => {
      const storageRef = ref(storage, `findings/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const newImage = {
              imageSource: downloadURL,
              imgId: `${Date.now()}`
            };

            setTreatImages((prevURLs) => [...prevURLs, newImage]);

            if (file === filesToUpload[filesToUpload.length - 1]) {
              setInputKey(Date.now());
            }
          });
        }
      );
    });
  };
  const handleTreatFileChange = (e) => {
    const selectedFiles = e.target.files;
    setTreatFiles(selectedFiles); // Set the files in state
    uploadTreatFiles(selectedFiles); // Call uploadFiles immediately
  };
  const removeImage = (imgIdToRemove) => {
    setDownloadURLs((prevURLs) => prevURLs.filter(url => url.imgId !== imgIdToRemove));
  };
  const [treatComments, setTreatComments] = useState("")
  const [treatImages, setTreatImages] = useState([])
  const [treatFiles, setTreatFiles] = useState([])

  return (
    <>

         <div className="row" style={{marginBottom: 14,padding: 5,borderRadius: 12,background: "#F8FCFC",}}>
            
            
            <div className="col-lg-1">
              {props.item.gallery.length > 0 ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      height: 80,
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="finding-row-image"
                      src={props.item.gallery[0].imageSource}
                      alt={props.item.gallery[0].imgId}
                    />
                  </div>
                </>
              ) 
              
              
              
              : (
                <></>
              )}
            </div>


            <div className="col-lg-4">
              <h6>פרויקט: {project?.projectName}</h6>
              <p>תחום הליקוי: <b>{findingType.safetyReportAreaItem}</b></p>
              <p>
                קבצים מצורפים: <b>{props.item.gallery.length} קבצים</b> | סטטוס
                ממצא: <b style={{color: props.item.status === 'פעיל' ? '#EB6079' : '#6fcb9f'}}>
                  {
                    props.item.status
                  }
                  </b>
              </p>
            </div>


            <div className="col-lg-1" style={{ textAlign: "center" }}>
              <p style={{ marginTop: 14 }}>תאריך הקמה</p>
              <h6>{moment(props.item.createdAt).format("DD/MM/YYYY")}</h6>
            </div>

            <div className="col-lg-1" style={{ textAlign: "center" }}>
              <p style={{ marginTop: 14 }}>יעד לטיפול</p>
              <h6>{moment(props.item.targetDate).format("DD/MM/YYYY")}</h6>
            </div>

            <div className="col-lg-2" style={{ textAlign: "center" }}>
              <p style={{ marginTop: 14 }}>סטטוס</p>
              <h6>{props.item.status}</h6>
            </div>


            <div className="col-lg-1" style={{ textAlign: "center" }}>
              <p style={{ marginTop: 0 }}>ליקוי</p>
              <div className="badge">
                <h6 style={{ color: "#000000" }}>
                  {props.item.itemImpairmentSeverity}
                </h6>
              </div>
            </div>


            <div className="col-lg-2">
              <p style={{ marginTop: 14 }}>
                <b>למעקב של: </b>
                {tracker?.firstName} {tracker?.lastName}
                <br />
                <b>לטיפול של: </b>
                {treater?.firstName} {treater?.lastName}
                <br />
              </p>
            </div>


        </div>
    </>
  );
};

export default FindingItemExp;
