import emailjs from "emailjs-com";

export const sendEmailWithAttachment = async (emails, fileName, fileUrl, sender, subject) => {
  const serviceId = "service_faxspbm";
  const templateId = "template_3dy80wn";
  const userId = "ILHnLsgUpnsAV_fJT"; // From EmailJS dashboard

  console.log("EMAILS: " + emails.join(", "));
  const templateParams = {
    to_email: emails.join(","),
    subject: "New Report Generated",
    message: `A new report (${fileName}) has been generated. You can download it here: ${fileUrl}`,
    file_url: fileUrl,
    list: emails.join(","),
    from_contact: sender,
    subject_title: subject
  };

  try {
    await emailjs.send(serviceId, templateId, templateParams, userId);
    console.log("Email sent successfully!");
  } catch (error) {
    console.error("Error sending email:", error);
  }
};
