import React, { useEffect, useState, useRef } from "react";
import { auth, database, storage } from "../../services/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {collection,addDoc,doc,deleteDoc,updateDoc,arrayUnion} from "firebase/firestore";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import SafetyTestItem from "./SafetyTestItem";
import useDashboardStore from "../../store/useDashboardStore";
import {saveLog} from '../../services/createLog'
import { useDropzone } from 'react-dropzone';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import TrainingGroupItemExp from "./TrainingGroupItemExp";
import { FaRegFilePdf } from "react-icons/fa";

const TrainingsItem = () => {

  const {
    myCompany,
    userPersonalData,
    selectedCompany,
    selectedSite,
    employees,
    companies,
    safetyCategories,
    safetyTests,
    myPlan
  } = useDashboardStore();

  const [showModal, setShowModal] = useState(false);
  const exportRef = React.useRef();
  const [trainingDesc, settrainingDesc] = useState("");
  const [trainingDate, settrainingDate] = useState("");
  const [trainingNextDate, settrainingNextDate] = useState("");
  const [trainingFile, settrainingFile] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [companiesArr, setCompaniesArr] = useState([]);
  const [employeesArr, setEmployeesArr] = useState([]);
  const [progress, setProgress] = useState(0);
  const [alltTrainingGroups, setalltTrainingGroups] = useState([]);
  const [alltTrainingGroupsOrig, setalltTrainingGroupsOrig] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedTrainingCategory, setSelectedTrainingCategory] = useState("");
  const modalRef = useRef(null); // Add this line at the top of your component
  const [trainingCategories, setTrainingCateories] = useState([]);
  const fileInputRef = useRef(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState('');

  const today = new Date();
  const [pdfPreviewUrls, setPdfPreviewUrls] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setPdfPreviewUrls(prevFiles => [...prevFiles, ...newFiles]);
    }
  });
  useEffect(() => {
    return () => {
      pdfPreviewUrls.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [pdfPreviewUrls]);


  useEffect(() => {
    setCompaniesArr(companies);
    setTrainingCateories(safetyCategories);

    //const allEmployees = employees.filter(x => x.associateCompany === selectedCompany.id);

    const allEmployees = employees
    .filter(x => x.associateCompany === selectedCompany.id)
    .sort((a, b) => {
      // First, compare by firstName
      const firstNameComparison = a.firstName.localeCompare(b.firstName);

      // If firstName is the same, compare by lastName
      return firstNameComparison !== 0
        ? firstNameComparison
        : a.lastName.localeCompare(b.lastName);
    });
    setEmployeesArr(allEmployees);

    const allSafetyItems = safetyTests
    .filter(x => x.associateCompanyId === selectedCompany.id)
    .sort((a, b) => a.trainingNextDate - b.trainingNextDate);
    setalltTrainingGroups(allSafetyItems);
    setalltTrainingGroupsOrig(allSafetyItems);

  },[safetyTests, selectedCompany])


  const handleSelect = (option) => {
    const emp = employeesArr.find((x) => x.id === option);
    if (!selectedEmployees.includes(option)) {
      setSelectedEmployees([...selectedEmployees, emp]);
      setEmployeesArr(employeesArr.filter((x) => x.id !== option));
    }
    setSelectedOption("");
  };

  const handleRemove = (option) => {
    const updatedValues = selectedEmployees.filter((v) => v !== option);
    setSelectedEmployees(updatedValues);
    if (!employeesArr.includes(option)) {
      if (!employeesArr.some((employee) => employee.id === option.id)) {
        setEmployeesArr([...employeesArr, option]);
      }
    }
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      settrainingFile(file);
      if (file.type === "application/pdf") {
        const url = URL.createObjectURL(file);
        setPdfPreviewUrl(url);
      } else {
        setPdfPreviewUrl('');
      }
    }
  };






  const addNewCommittee = async () => {
    if (
      selectedEmployees.length > 0 &&
      selectedCompany !== "" &&
      pdfPreviewUrls.length > 0 &&
      selectedTrainingCategory !== ""
    ) {

      setIsLoading(true);

      try {
        const uploadPromises = pdfPreviewUrls.map(file =>
          uploadBytesResumable(ref(storage, `files/${file.name}`), file)
        );
        const uploadTasks = await Promise.all(uploadPromises);
        const downloadUrls = await Promise.all(
          uploadTasks.map(task => getDownloadURL(task.ref))
        );

        const trainingDateVal = new Date(trainingDate);
            const trainingNextDateVal = new Date(trainingNextDate);
            const trainingItem = trainingCategories.find(x => x.id === selectedTrainingCategory);

            let group = {
              createdBy: auth.currentUser.uid,
              createdAt: Date.now(),
              associateCompanyId: selectedCompany.id,
              trainingDate: trainingDateVal.getTime(),
              trainingDesc: trainingDesc,
              trainingFiles: downloadUrls,
              selectedSite: selectedSite.siteId,
              trainingName: trainingItem.safetyTestingCategoryItem,
              trainingNextDate: trainingNextDateVal.getTime(),
              employees: selectedEmployees,
              associateId: userPersonalData.associateId,
              selectedTrainingCategory: selectedTrainingCategory,
            };

            const docRef = await addDoc(collection(database, "safetyTests"), group);
            group.id = docRef.id;
            useDashboardStore.getState().addSafetyTest(group);



            const logData = {
              associateCompanyId: selectedCompany.id,
              associateId: userPersonalData.associateId,
              createdBy: userPersonalData.uuid,
              logTitle: "הוספת תרגיל בטיחות חדש",
              logDescription: `${userPersonalData.firstName} ${userPersonalData.lastName} יצר תרגיל בטיחות חדש עבור ${selectedCompany.companyName}, סוג התרגיל: ${trainingItem.safetyTestingCategoryItem}`
            };
            saveLog(logData);

          setalltTrainingGroups((prevGroups) => {
            const updatedGroups = [...prevGroups, group];
            const sortedGroups = updatedGroups.sort((a, b) => a.trainingDate - b.trainingDate);
            return sortedGroups;
          });

          selectedEmployees.forEach(async (item) => {
            const employeesRef = doc(database, "employees", item.id);
            const training = {
              itemType: "הדרכות",
              itemId: generateRandomId(),
              itemName: trainingItem.safetyTestingCategoryItem,
              createdAt: Date.now(),
              itemDate: trainingDateVal.getTime(),
              nextItemDate: trainingNextDateVal.getTime(),
              selectedTrainingCategory: selectedTrainingCategory,
            };
            await updateDoc(employeesRef, {
              trainings: arrayUnion(training),
            });
          });

          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }

          // if (modalRef.current) {
          //   const modalInstance = Modal.getInstance(modalRef.current);
          //   if (modalInstance) {
          //     modalInstance.hide();
          //   } else {
          //     new Modal(modalRef.current).hide();
          //   }
          // }

          // setTimeout(() => {
          //   document.querySelectorAll(".modal-backdrop.fade.show").forEach((backdrop) => backdrop.remove());
          //   document.body.classList.remove("modal-open");
          // }, 150);

          settrainingDesc("");
          settrainingDate(today);
          settrainingNextDate(today);
          setSelectedTrainingCategory("");
          setPdfPreviewUrls([]);
          setSelectedEmployees([]);
          setIsLoading(false);

      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      toast.error("כל השדות בטופס חובה למילוי");
    }
  };
  






  function generateRandomId() {
    return Math.random().toString(36).substr(2, 10);
  }

  const removeCommittee = async (item) => {
    const isConfirmed = window.confirm("האם אתה בטוח שברצונך להסיר את התרגיל?");
    if (!isConfirmed) {
      return;
    }
    try {
      await deleteDoc(doc(database, "safetyTests", item.id)).then(async (res) => {
        useDashboardStore.getState().removeSafetyTest(item.id); // Update store
        toast.success("התרגיל הוסר בהצלחה");
      }).catch((err) => {
        toast.error(err.message);
      });
    } catch (error) {
      toast.error(error.message);
    }
  };
  

  const updateComit = async (
    selectedEmployees,
    trainingDate,
    trainingNextDate,
    trainingDesc,
    trainingFile,
    id,
    selectedTrainingCategory
  ) => {

    const trainingItem = trainingCategories.find(x => x.id === selectedTrainingCategory);
    const updateData = {
      trainingName: trainingItem.safetyTestingCategoryItem,
      trainingDesc,
      trainingDate: new Date(trainingDate).getTime(),
      trainingNextDate: new Date(trainingNextDate).getTime(),
      selectedTrainingCategory: selectedTrainingCategory,
      employees: selectedEmployees,
    };
  
    if (trainingFile) {
      const storageRef = ref(storage, `files/${trainingFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, trainingFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(prog);
        },
        (error) => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            const committeesRef = doc(database, "safetyTests", id);
            await updateDoc(committeesRef, {
              ...updateData,
              trainingFile: downloadURL,
            }).then(() => {
              useDashboardStore.getState().updateSafetyTest(id, { ...updateData, trainingFile: downloadURL }); // Update store
            });
          });
        }
      );
    } else {
      const committeesRef = doc(database, "safetyTests", id);
      await updateDoc(committeesRef, updateData).then(() => {
        useDashboardStore.getState().updateSafetyTest(id, updateData); // Update store
      });
    }
  };

  const updateListByDate = (toDateVal) => {
    setToDate(toDateVal);
    const fromDateTimestamp = new Date(fromDate).getTime();
    const toDateTimestamp = new Date(toDateVal).getTime();
    const filterGroups = alltTrainingGroups.filter(
      (group) =>
        group.trainingDate >= fromDateTimestamp &&
        group.trainingDate <= toDateTimestamp
    );

    setalltTrainingGroups(filterGroups);
  };

  const filterListByCateory = (newValue) => {
    let filteredFindings = alltTrainingGroupsOrig;
    if (newValue) {
      filteredFindings = filteredFindings.filter(report => report.selectedTrainingCategory === newValue);
    }
    setalltTrainingGroups(filteredFindings);
    setSelectedTrainingCategory(newValue);
  }








  const handleDownloadPdf = async () => {
    const element = exportRef.current; // Get the element to export
    const canvas = await html2canvas(element, { scale: 1, useCORS: true }); // Generate canvas from DOM
    const imgData = canvas.toDataURL("image/png"); // Convert canvas to data URL

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 190; // A4 width minus some margin
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjusted height based on width
    const pageHeight = pdf.internal.pageSize.height - 20; // A4 height minus some margin for footer

    let heightLeft = imgHeight;
    let position = 0; // Start position for drawing

    // Add the first page with content
    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add subsequent pages if needed
    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft); // Adjust position for next slice
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Add footer on each page
    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8); // Footer font size
      pdf.setTextColor(100); // Footer text color
      pdf.text(
        "This report was generated with Lamemone ERP",
        105,
        pdf.internal.pageSize.height - 10,
        {
          align: "center",
        }
      );
    }

    const timestamp = new Date().getTime();
    pdf.save(`training_${timestamp}.pdf`);
  };

  const handleModal = async () => {
    setShowModal(true); // Open modal to ensure the content is visible for screenshot
  };

  const exportToExcel = (allTrainingGroups) => {
    const timestamp = new Date().getTime();
    const fileName = `Training_${timestamp}.xlsx`;
  
    // Define headers in the order you want them to appear (right to left)
    const headers = [
        { header: 'עובדים שנכחו', key: 'employees', width: 40 },
        { header: 'תיאור ההדרכה', key: 'trainingDesc', width: 20 },
        { header: 'שם ההדרכה', key: 'trainingName', width: 20 },
        { header: 'ההדרכה הבאה', key: 'trainingNextDate', width: 15 },
        { header: 'תאריך ההדרכה', key: 'trainingDate', width: 15 },
        { header: 'נוצר בתאריך', key: 'createdAt', width: 15 },
        { header: 'מספר מזהה', key: 'id', width: 10 },
    ];
  
    const data = allTrainingGroups.map(group => ({
      id: group.id,
      createdAt: moment(group.createdAt).format('DD/MM/YYYY'),
      trainingDate: moment(group.trainingDate).format('DD/MM/YYYY'),
      trainingNextDate: moment(group.trainingNextDate).format('DD/MM/YYYY'),
      trainingName: group.trainingName,
      trainingDesc: group.trainingDesc,
      employees: group.employees.map(emp => `${emp.firstName} ${emp.lastName} ${emp.employeeId}`).join(', '),
    }));
  
    const ws = XLSX.utils.json_to_sheet(data, { header: headers.map(header => header.key) });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "TrainingReports");
    XLSX.writeFile(wb, fileName);
  };







  return (
    <>
      <ToastContainer />
      <div
        className="modal fade"
        id="exampleModal"
        ref={modalRef}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">הוספת תרגיל בטיחות חדש</h1>
              <button type="button"className="btn-close"data-bs-dismiss="modal"aria-label="Close"></button>
            </div>

            <div className="modal-body">


              <label className="form-label">קטגוריית תרגיל בטיחות</label>
              <select
                value={selectedTrainingCategory}
                onChange={(e) => setSelectedTrainingCategory(e.target.value)}
                className="form-control"
              >
                <option value="">בחר קטגוריה מהרשימה...</option>
                {trainingCategories.length > 0 && (
                  <>
                    {trainingCategories.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.safetyTestingCategoryItem}
                      </option>
                    ))}
                  </>
                )}
              </select>

              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label">תאריך התרגיל</label>
                  <input
                    type="date"
                    className="form-control"
                    value={moment(trainingDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      settrainingDate(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label">תאריך התרגיל הבא</label>
                  <input
                    type="date"
                    className="form-control"
                    value={moment(trainingNextDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      settrainingNextDate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <label className="form-label">תיאור התרגיל</label>
              <textarea
                className="form-control"
                value={trainingDesc}
                onChange={(e) => {
                  settrainingDesc(e.target.value);
                }}
              ></textarea>

<label className="form-label">העלאת קובץ</label>
              
              <div {...getRootProps({ className: 'dropzone', style: dropzoneStyle })}>
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>ניתן לגרור קבצים לכאן או להקליק כאן על מנת לבחור קבצים ידנית</p>
                }
              </div>

              <div style={thumbsContainer}>
                {pdfPreviewUrls.map((file, index) => (
                  <div style={thumb} key={index}>
                    <div style={thumbInner}>
                      <iframe
                        src={file.preview}
                        style={img}
                        frameBorder="0"
                        title={`PDF Preview ${index + 1}`}
                      ></iframe>
                    </div>
                  </div>
                ))}
              </div>

              <label className="form-label">נכחו בתרגיל</label>
              <select
                className="form-control"
                onChange={(e) => {
                  handleSelect(e.target.value);
                }}
              >
                <option value="0">בחר עובדים מהרשימה...</option>
                {employeesArr.length > 0 && (
                  <>
                    {employeesArr.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.lastName} {employee.firstName} {employee.employeeId}
                      </option>
                    ))}
                  </>
                )}
              </select>
              {selectedEmployees.length > 0 && (
                <>
                  {selectedEmployees.map((emp) => (
                    <>
                      <span
                        key={emp.empId}
                        onClick={() => {
                          handleRemove(emp);
                        }}
                        style={{ marginLeft: 6 }}
                        className="badge text-bg-info"
                      >
                        {emp.firstName} {emp.lastName}
                      </span>
                    </>
                  ))}
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal">סגירה</button>
              <button
                onClick={addNewCommittee}
                type="button"
                className="btn btn-success">הוספת תרגיל חדש</button>
            </div>
          </div>
        </div>
      </div>


      <div className="row heading-container">
        <div className="col-lg-9">
          <h3>תרגילי בטיחות | {selectedCompany.companyName}</h3>
        </div>
        <div className="col-lg-3 heading-btn-container">
          <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-success btn-lg">הוספת תרגיל בטיחות</button>
          {
            myPlan.actions && <>
            <button style={{marginRight:12}} onClick={handleModal} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
            <button className="btn btn-success btn-lg" style={{marginRight:10}} onClick={() => {exportToExcel(alltTrainingGroups)}}><PiMicrosoftExcelLogoFill color="#ffffff" size={30} /></button>
            </>
          }
        </div>
      </div>

      <div className="row heading-container-filter">


        <div className="col-lg-3">
          <label className="form-label">סינון לפי קטגוריה</label>
          <select className="form-control" value={selectedTrainingCategory} onChange={(e) => filterListByCateory(e.target.value)}>
            <option value="">בחר קטגוריה מהרשימה...</option>
            {trainingCategories.length > 0 && (
              <>
                {trainingCategories.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.safetyTestingCategoryItem}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>


        <div className="col-lg-3">
          <label className="form-label">מתאריך</label>
          <input
            type="date"
            className="form-control"
            value={moment(fromDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
        </div>


        <div className="col-lg-3">
          <label className="form-label">עד תאריך</label>
          <input
            type="date"
            className="form-control"
            value={moment(toDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              updateListByDate(e.target.value);
            }}
          />
        </div>


      </div>

      <div className="row" style={{ padding: 50 }}>
        {isLoading ? (
          <>
            <div className="spinner-border text-info" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </>
        ) : (
          <>
            {alltTrainingGroups.length > 0 ? (
              <>
                {alltTrainingGroups.map((group) => (
                  <SafetyTestItem
                    safetyCategories={safetyCategories}
                    updateComit={updateComit}
                    employeesArr={employeesArr}
                    removeCommittee={removeCommittee}
                    group={group}
                  />
                ))}
              </>
            ) : (
              <></>
            )}



<Modal
        show={showModal}
        dialogClassName="custom-modal"
        onHide={() => setShowModal(false)}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" ref={exportRef} style={{ padding: 50 }}>
            
            <div className="row">
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-2">
                    <img src="/images/logo_black.png" style={{ width: 60 }} />
                  </div>
                  <div className="col-lg-10">
                    <p className="companyInfo">
                      דוח בטיחות זה
                      <br />
                      הופק באמצעות
                      <br />
                      מערכת לממונה
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                {myCompany && myCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={myCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{myCompany.companyName}</b>
                          <br />
                          {myCompany.companyAddress}, {myCompany.companyCity}
                          <br />
                          ח.פ. {myCompany.companyTaxId}
                          <br />
                          {myCompany.companyEmail} | {myCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-lg-4">
                {selectedCompany && selectedCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={selectedCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{selectedCompany.companyName}</b>
                          <br />
                          {selectedCompany.companyAddress},{" "}
                          {selectedCompany.companyCity}
                          <br />
                          ח.פ. {selectedCompany.companyTaxId}
                          <br />
                          {selectedCompany.companyEmail} |{" "}
                          {selectedCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 50, marginBottom:50 }}>
              <div className="col-lg-12">
                <h2>הדרכות | {selectedCompany.companyName}</h2>
              </div>
            </div>


            {alltTrainingGroups.length > 0 ? (
              <>
                {alltTrainingGroups.map((group) => (
                  <TrainingGroupItemExp
                    key={group.id}
                    updateComit={updateComit}
                    employeesArr={employeesArr}
                    removeCommittee={removeCommittee}
                    group={group}
                  />
                ))}
              </>
            ) : (
              <></>
            )}



            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleDownloadPdf}
            className="btn btn-success btn-lg">שמירת קובץ</button>
        </Modal.Footer>
      </Modal>
          </>
        )}
      </div>
    </>
  );
};
const dropzoneStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginBottom: '20px'
};
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};
const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: '100%',
  height: 200,
  padding: 4,
  boxSizing: 'border-box'
};
const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  width:'100%'
};
const img = {
  display: 'block',
  width: '100%',
  height: '100%'
};
export default TrainingsItem;
