import React, { useCallback, useEffect, useState,useRef  } from "react";
import { auth, database, storage } from "../../services/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection,writeBatch,getDocs, addDoc, doc, deleteDoc, updateDoc, arrayUnion } from "firebase/firestore";
import moment from "moment";
import CommitteeCard from "../../components/CommitteeCard";
import { toast, ToastContainer } from "react-toastify";
import {saveLog} from '../../services/createLog'
import useDashboardStore from "../../store/useDashboardStore";
import { useDropzone } from 'react-dropzone';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import CommitteeCardExp from "../../components/CommitteeCardExp";
import { FaRegFilePdf } from "react-icons/fa";


const Comittees = () => {

  const {
    myCompany,
    userPersonalData,
    selectedCompany,
    selectedSite,
    committees,
    employees,
    myPlan
  } = useDashboardStore();
  const [showModal, setShowModal] = useState(false);
  const exportRef = React.useRef();
  const [committeeName, setCommitteeName] = useState("");
  const [committeeDesc, setCommitteeDesc] = useState("");
  const [committeeDate, setCommitteeDate] = useState("");
  const [committeeNextDate, setCommitteeNextDate] = useState("");
  const [committeeFile, setCommitteeFile] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [companiesArr, setCompaniesArr] = useState([]);
  const [employeesArr, setEmployeesArr] = useState([]);
  const [progress, setProgress] = useState(0);
  const [allCommitteess, setAllCommittees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReportSent, setIsReportSent] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const fileInputRef = useRef(null);
  const modalRef = useRef(null); // Add this line at the top of your component
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState('');
  const today = new Date();
  const [pdfPreviewUrls, setPdfPreviewUrls] = useState([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setPdfPreviewUrls(prevFiles => [...prevFiles, ...newFiles]);
    }
  });

  useEffect(() => {
    return () => {
      pdfPreviewUrls.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [pdfPreviewUrls]);



  useEffect(() => {

    const allEmployees = employees
    .filter(x => x.associateCompany === selectedCompany.id)
    .sort((a, b) => {
      // First, compare by firstName
      const firstNameComparison = a.firstName.localeCompare(b.firstName);

      // If firstName is the same, compare by lastName
      return firstNameComparison !== 0
        ? firstNameComparison
        : a.lastName.localeCompare(b.lastName);
    });


    setEmployeesArr(allEmployees);

    const allCommittes = committees
    .filter(x => x.associateCompanyId === selectedCompany.id)
    .sort((a, b) => a.committeeNextDate - b.committeeNextDate);
    setAllCommittees(allCommittes);

  },[selectedCompany, committees])

  const handleSelect = (option) => {
    const emp = employeesArr.find((x) => x.id === option);
    if (!selectedEmployees.includes(option)) {
      setSelectedEmployees([...selectedEmployees, emp]);
      setEmployeesArr(employeesArr.filter((x) => x.id !== option));
    }
    setSelectedOption("");
  };

  
  const handleRemove = (option) => {
    const updatedValues = selectedEmployees.filter((v) => v !== option);
    setSelectedEmployees(updatedValues);
    if (!employeesArr.includes(option)) {
      if (!employeesArr.some((employee) => employee.id === option.id)) {
        setEmployeesArr([...employeesArr, option]);
      }
    }
  };

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      const handleHide = () => {
        document.body.classList.remove("modal-open");
        document.querySelector(".modal-backdrop.fade.show")?.remove();
      };
      modalElement.addEventListener("hide.bs.modal", handleHide);
      return () => {
        modalElement.removeEventListener("hide.bs.modal", handleHide);
      };
    }
  }, []);

  const addNewCommittee = async () => {


    if (
      selectedEmployees.length > 0 && 
      selectedCompany && 
      committeeName !== "" && 
      pdfPreviewUrls.length > 0) {

        setIsLoading(true);
        try {

          const uploadPromises = pdfPreviewUrls.map(file =>
            uploadBytesResumable(ref(storage, `files/${file.name}`), file)
          );
          const uploadTasks = await Promise.all(uploadPromises);
          const downloadUrls = await Promise.all(
            uploadTasks.map(task => getDownloadURL(task.ref))
          );

          const vdate = new Date(committeeDate);
            const vNextdate = new Date(committeeNextDate);
  
            let committee = {
              createdBy: auth.currentUser.uid,
              createdAt: Date.now(),
              associateCompanyId: selectedCompany.id,
              committeeName: committeeName,
              committeeFiles: downloadUrls,
              committeeDesc: committeeDesc,
              selectedSite: selectedSite.siteId,
              committeeDate: vdate.getTime(),
              committeeNextDate: vNextdate.getTime(),
              employees: selectedEmployees,
              associateId: userPersonalData.associateId,
              isReportSent: isReportSent
            };
            const docRef = await addDoc(collection(database, "committees"), committee);
            committee.id = docRef.id;
            useDashboardStore.getState().addCommittee(committee);
  
            const logData = {
              associateCompanyId: selectedCompany.id,
              associateId: userPersonalData.associateId,
              createdBy: userPersonalData.uuid,
              logTitle: "הוספת ועדת בטיחות",
              logDescription: `${userPersonalData.firstName} ${userPersonalData.lastName} יצר ועדת בטיחות חדשה עבור ${selectedCompany.companyName}, בשם: ${committeeName}`
            };

            saveLog(logData);

            setAllCommittees((prevCommittees) => {
              const updatedCommittees = [...prevCommittees, committee];
              const sortedCommittees = updatedCommittees.sort((a, b) => a.committeeDate - b.committeeDate);
              return sortedCommittees;
            });
  
            selectedEmployees.forEach(async (item) => {
              const employeesRef = doc(database, "employees", item.id);
              const training = {
                itemType: 'ועדת בטיחות',
                itemId: generateRandomId(),
                itemName: committeeName,
                createdAt: Date.now(),
                itemDate: vdate.getTime(),
              };
              await updateDoc(employeesRef, {
                trainings: arrayUnion(training),
              });
            });
  
            setCommitteeDate(today);
            setCommitteeNextDate(today);
            setCommitteeName("");
            setCommitteeDesc("");
            setPdfPreviewUrls([]);
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
  
            // if (modalRef.current) {
            //   const modalInstance = Modal.getInstance(modalRef.current);
            //   if (modalInstance) {
            //     modalInstance.hide();
            //   } else {
            //     new Modal(modalRef.current).hide();
            //   }
            // }
  
            // setTimeout(() => {
            //   document.querySelectorAll(".modal-backdrop.fade.show").forEach((backdrop) => backdrop.remove());
            //   document.body.classList.remove("modal-open");
            // }, 150); // Adjust delay as needed
  
            setSelectedEmployees([]);
            setIsLoading(false)

        } catch (error) {
          setIsLoading(false)
          toast.error(error.message);
        }
      } else {
        toast.error("כל השדות בטופס חובה למילוי");
      }
  };
  

  function generateRandomId() {
    return Math.random().toString(36).substr(2, 10);
  }

  const removeCommittee = async (item) => {
    const isConfirmed = window.confirm("האם למחוק את הועדה לצמיתות?");
  
    if (isConfirmed) {
      try {
        await deleteDoc(doc(database, "committees", item.id)).then(async (res) => {
          useDashboardStore.getState().removeCommittee(item.id); // Update store
          toast.success("הועדה הוסרה בהצלחה");
        }).catch((err) => {
          toast.error(err.message);
        });
      } catch (error) {
        toast.error(error.message);
      }
    }
  };
  

  const updateComit = async (
    selectedEmployees,
    committeeName,
    committeeDate,
    committeeNexDate,
    committeeDesc,
    committeeFile,
    id,
    isReportSent
  ) => {
    const updateData = {
      committeeName,
      committeeDesc,
      committeeDate: new Date(committeeDate).getTime(),
      committeeNextDate: new Date(committeeNexDate).getTime(),
      employees: selectedEmployees,
      isReportSent:isReportSent
    };
  
    if (committeeFile) {
      const storageRef = ref(storage, `files/${committeeFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, committeeFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(prog);
        },
        (error) => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            const committeesRef = doc(database, "committees", id);
            await updateDoc(committeesRef, {
              ...updateData,
              committeeFile: downloadURL,
            }).then(() => {
              useDashboardStore.getState().updateCommittee(id, { ...updateData, committeeFile: downloadURL }); // Update store
            });
          });
        }
      );
    } else {
      const committeesRef = doc(database, "committees", id);
      await updateDoc(committeesRef, updateData).then(() => {
        useDashboardStore.getState().updateCommittee(id, updateData); // Update store
      });
    }
  };
  

  const updateListByDate = (toDateVal) => {
    setToDate(toDateVal);
    const fromDateTimestamp = new Date(fromDate).getTime();
    const toDateTimestamp = new Date(toDateVal).getTime();
    const filteredCommittees = allCommitteess.filter(
      (committee) => committee.committeeDate >= fromDateTimestamp && committee.committeeDate <= toDateTimestamp
    );

    setAllCommittees(filteredCommittees);
  };






  const handleDownloadPdf = async () => {
    const element = exportRef.current; // Get the element to export
    const canvas = await html2canvas(element, { scale: 1, useCORS: true }); // Generate canvas from DOM
    const imgData = canvas.toDataURL("image/png"); // Convert canvas to data URL

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 190; // A4 width minus some margin
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjusted height based on width
    const pageHeight = pdf.internal.pageSize.height - 20; // A4 height minus some margin for footer

    let heightLeft = imgHeight;
    let position = 0; // Start position for drawing

    // Add the first page with content
    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Add subsequent pages if needed
    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft); // Adjust position for next slice
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Add footer on each page
    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8); // Footer font size
      pdf.setTextColor(100); // Footer text color
      pdf.text(
        "This report was generated with Lamemone ERP",
        105,
        pdf.internal.pageSize.height - 10,
        {
          align: "center",
        }
      );
    }

    const timestamp = new Date().getTime();
    pdf.save(`committee_${timestamp}.pdf`);
  };
  const handleModal = async () => {
    setShowModal(true); // Open modal to ensure the content is visible for screenshot
  };
  const exportToExcel = (allCommitteess) => {
    const timestamp = new Date().getTime();
    const fileName = `Committee_${timestamp}.xlsx`;

    // Define headers in the order you want them to appear (right to left)
    const headers = [
        { header: 'עובדים שנכחו', key: 'employees', width: 40 },
        { header: 'תיאור הוועדה', key: 'committeeDesc', width: 20 },
        { header: 'שם הוועדה', key: 'committeeName', width: 20 },
        { header: 'הוועדה הבאה', key: 'committeeNextDate', width: 15 },
        { header: 'תאריך הוועדה', key: 'committeeDate', width: 15 },
        { header: 'נוצר בתאריך', key: 'createdAt', width: 15 },
        { header: 'מספר מזהה', key: 'id', width: 10 },
    ];

    const data = allCommitteess.map(group => ({
      id: group.id,
      createdAt: moment(group.createdAt).format('DD/MM/YYYY'),
      committeeDate: moment(group.committeeDate).format('DD/MM/YYYY'),
      committeeNextDate: moment(group.committeeNextDate).format('DD/MM/YYYY'),
      committeeName: group.committeeName,
      committeeDesc: group.committeeDesc,
      employees: group.employees.map(emp => `${emp.firstName} ${emp.lastName} ${emp.employeeId}`).join(', '),
    }));

    // Create a worksheet from the data array, skipping header generation.
    const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });

    // Manually add the headers to the first row of the sheet
    headers.forEach((header, index) => {
        ws[XLSX.utils.encode_cell({ c: index, r: 0 })] = { v: header.header };
    });

    // Append the data starting from the second row
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reports");
    XLSX.writeFile(wb, fileName);
  };



  return (
    <>
      <ToastContainer />

      <div
        ref={modalRef}
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                הוספת ועדת בטיחות חדשה
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">


              <label className="form-label">שם הועדה</label>
              <input
                type="text"
                className="form-control"
                value={committeeName}
                onChange={(e) => {
                  setCommitteeName(e.target.value);
                }}
              />


              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label">תאריך הועדה</label>
                  <input
                    type="date"
                    className="form-control"
                    value={moment(committeeDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setCommitteeDate(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="form-label">תאריך הועדה הבאה</label>
                  <input
                    type="date"
                    className="form-control"
                    value={moment(committeeNextDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setCommitteeNextDate(e.target.value);
                    }}
                  />
                </div>
              </div>


              <label className="form-label">תיאור הועדה</label>
              <textarea
                className="form-control"
                value={committeeDesc}
                onChange={(e) => {
                  setCommitteeDesc(e.target.value);
                }}></textarea>

              <div className="col-lg-12" style={{marginTop:20,marginBottom:20}}>
              <input
                    onChange={(e) => setIsReportSent(e.target.checked)}
                    checked={isReportSent}
                    className="form-check-input"
                    type="checkbox"
                    role="switch" />{" "}<label className="form-check-label">מעקב לשליחת וועדת בטיחות למשרד העבודה</label>
              </div>

<label className="form-label">העלאת קובץ</label>
              
              <div {...getRootProps({ className: 'dropzone', style: dropzoneStyle })}>
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>ניתן לגרור קבצים לכאן או להקליק כאן על מנת לבחור קבצים ידנית</p>
                }
              </div>

              <div style={thumbsContainer}>
                {pdfPreviewUrls.map((file, index) => (
                  <div style={thumb} key={index}>
                    <div style={thumbInner}>
                      <iframe
                        src={file.preview}
                        style={img}
                        frameBorder="0"
                        title={`PDF Preview ${index + 1}`}
                      ></iframe>
                    </div>
                  </div>
                ))}
              </div>

              <label className="form-label">נכחו בועדה</label>
              <select
                className="form-control"
                onChange={(e) => {
                  handleSelect(e.target.value);
                }}
              >
                <option value="0">בחר עובדים מהרשימה...</option>
                {employeesArr.length > 0 && (
                  <>
                    {employeesArr.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.lastName} {employee.firstName} | {employee.employeeId}
                      </option>
                    ))}
                  </>
                )}
              </select>
              {selectedEmployees.length > 0 && (
                <>
                  {selectedEmployees.map((emp) => (
                    <>
                      <span
                        key={emp.empId}
                        onClick={() => {handleRemove(emp)}}
                        style={{ marginLeft: 6 }}
                        className="badge text-bg-info">
                        {emp.firstName} {emp.lastName}
                      </span>
                    </>
                  ))}
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal">סגירה</button>
              <button
                onClick={addNewCommittee}
                type="button"
                className="btn btn-success">הוספת ועדה חדשה</button>
            </div>
          </div>
        </div>
      </div>

      <div className="row heading-container">
        <div className="col-lg-9">
          <h3>ועדות בטיחות | {selectedCompany.companyName}</h3>
        </div>
        <div className="col-lg-3 heading-btn-container">
          <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-success btn-lg">הוספת ועדת בטיחות</button>
          {
            myPlan.actions && <>
            <button style={{marginRight:12}} onClick={handleModal} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
            <button className="btn btn-success btn-lg" style={{marginRight:10}} onClick={() => {exportToExcel(allCommitteess)}}><PiMicrosoftExcelLogoFill color="#ffffff" size={30} /></button>
            </>
          }
        </div>
      </div>


      <div className="row heading-container-filter">

        <div className="col-lg-3">
        <label className="form-label">מתאריך</label>
        <input
                type="date"
                className="form-control"
                value={moment(fromDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
              />
        </div>
        <div className="col-lg-3">
        <label className="form-label">עד תאריך</label>
        <input
                type="date"
                className="form-control"
                value={moment(toDate).format("YYYY-MM-DD")}
                onChange={(e) => {updateListByDate(e.target.value);}}
              />
        </div>
      </div>

      <div className="row" style={{ padding: 50 }}>
        {isLoading ? (
          <>
            <div className="spinner-border text-info" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </>
        ) : (
          <>
            {allCommitteess || allCommitteess.length > 0 ? (
              <>
                {
                  allCommitteess.map((comit) => (
                  <CommitteeCard
                    key={comit.id}
                    updateComit={updateComit}
                    employeesArr={employeesArr}
                    removeCommittee={removeCommittee}
                    comit={comit}
                  />
                ))}
              </>
            ) : (
              <></>
            )}


      <Modal
        show={showModal}
        dialogClassName="custom-modal"
        onHide={() => setShowModal(false)}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" ref={exportRef} style={{ padding: 50 }}>
            
            <div className="row">
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-2">
                    <img src="/images/logo_black.png" style={{ width: 60 }} />
                  </div>
                  <div className="col-lg-10">
                    <p className="companyInfo">
                      דוח בטיחות זה
                      <br />
                      הופק באמצעות
                      <br />
                      מערכת לממונה
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                {myCompany && myCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={myCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{myCompany.companyName}</b>
                          <br />
                          {myCompany.companyAddress}, {myCompany.companyCity}
                          <br />
                          ח.פ. {myCompany.companyTaxId}
                          <br />
                          {myCompany.companyEmail} | {myCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-lg-4">
                {selectedCompany && selectedCompany.companyLogo ? (
                  <>
                    <div className="row">
                      <div className="col-lg-3">
                        <img
                          src={selectedCompany.companyLogo}
                          style={{ width: 80 }}
                        />
                      </div>
                      <div className="col-lg-9">
                        <p className="companyInfo">
                          <b>{selectedCompany.companyName}</b>
                          <br />
                          {selectedCompany.companyAddress},{" "}
                          {selectedCompany.companyCity}
                          <br />
                          ח.פ. {selectedCompany.companyTaxId}
                          <br />
                          {selectedCompany.companyEmail} |{" "}
                          {selectedCompany.companyPhone}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row" style={{ marginTop: 50, marginBottom:50 }}>
              <div className="col-lg-12">
                <h2>וועדות בטיחות | {selectedCompany.companyName} |{" "}{selectedSite.projectName}</h2>
              </div>
            </div>

            {allCommitteess.length > 0 ? (
              <>
                {
                  allCommitteess.map((comit) => (
                  <CommitteeCardExp
                    key={comit.id}
                    updateComit={updateComit}
                    employeesArr={employeesArr}
                    removeCommittee={removeCommittee}
                    comit={comit}
                  />
                ))}
              </>
            ) : (
              <></>
            )}

            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleDownloadPdf}
            className="btn btn-success btn-lg">שמירת קובץ</button>
        </Modal.Footer>
      </Modal>




          </>
        )}
      </div>
    </>
  );
};


const dropzoneStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginBottom: '20px'
};
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};
const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: '100%',
  height: 200,
  padding: 4,
  boxSizing: 'border-box'
};
const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  width:'100%'
};
const img = {
  display: 'block',
  width: '100%',
  height: '100%'
};
export default Comittees;
