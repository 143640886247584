import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import useDashboardStore from "../../store/useDashboardStore";
import moment from "moment";
import FindingItemSmall from "./components/FindingItemSmall";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { IoCloudDownloadOutline } from "react-icons/io5";
import {Modal} from 'react-bootstrap'
import {storage} from '../../services/firebase-config'
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { sendEmailWithAttachment } from "../../services/emailService";
import { FaRegFilePdf } from "react-icons/fa";
import { RiMailSendLine } from "react-icons/ri";



const ReportDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { userPersonalData, selectedCompany, myCompany, selectedSite, safetyReportsAreas, safetyReports, employees } = useDashboardStore();
  const [selectedReport, setSelectedReport] = useState({});
  const exportRef = React.useRef();
  const [manager, setManager] = useState({});
  const [tracker, setTracker] = useState({});
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isExport, setIsExport] = useState(true);
  const [input, setInput] = useState("");
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    const report = safetyReports.find(x => x.id === id);
    setSelectedReport(report);

    const _manager = employees.find(x => x.id === report.manageInCharge);
    const _tracker = employees.find(x => x.id === report.trackInCharge);

    setManager(_manager)
    setTracker(_tracker)

  },[])


const handleModal = async (action) => {
    if(action === 'mail'){
      setIsExport(false)
    } else {
      setIsExport(true)
    }
    setShowModal(true);
  };


  let priorityColor = "";
  let textColor = "";

  if (parseFloat(selectedReport.totalAverage) >= 90) {
    priorityColor = "#67eb34";
    textColor = "#67eb34"
  }
  if (parseFloat(selectedReport.totalAverage) >= 70 && parseFloat(selectedReport.totalAverage) < 90) {
    priorityColor = "#0099cc";
    textColor = "#0099cc"
  }
  if (parseFloat(selectedReport.totalAverage) >= 50 && parseFloat(selectedReport.totalAverage) < 70)  {
    priorityColor = "#ffcc00";
    textColor = "#ffcc00"
  }
  if (parseFloat(selectedReport.totalAverage) >= 0 && parseFloat(selectedReport.totalAverage) < 50)  {
    priorityColor = "#EB6079";
    textColor = "#EB6079"
  }



  const handleKeyDown = (e) => {
    if ((e.key === "Enter" || e.key === "Tab") && input.trim() !== "") {
      e.preventDefault();
      const newEmail = input.trim();

      if (validateEmail(newEmail) && !emails.includes(newEmail)) {
        const updated = [...emails, newEmail];
        setEmails(updated);
      }
      setInput("");
    }
  };

  const removeEmail = (emailToRemove) => {
    const updated = emails.filter((email) => email !== emailToRemove);
    setEmails(updated);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };



  useEffect(() => {
    preloadImages();
  }, []);

  const preloadImages = async () => {
    const images = document.querySelectorAll("img");
    const loadImage = (img) => {
      return new Promise((resolve, reject) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = () => resolve();
          img.onerror = () => reject();
        }
      });
    };

    await Promise.all(Array.from(images).map(img => loadImage(img)));
    setImagesLoaded(true); // Set the state to true once all images are loaded
  };


  const handleDownloadPdf = async () => {

    if (!imagesLoaded) {
      toast.error("Images are still loading, please try again shortly.");
      return;
    }

    const element = exportRef.current; // Get the element to export
    const canvas = await html2canvas(element, { scale: 1, useCORS: true }); // Generate canvas from DOM
    const imgData = canvas.toDataURL('image/png'); // Convert canvas to data URL
  
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: 'a4'
    });
  
    const imgWidth = 190; // A4 width minus some margin
    const imgHeight = canvas.height * imgWidth / canvas.width; // Adjusted height based on width
    const pageHeight = pdf.internal.pageSize.height - 20; // A4 height minus some margin for footer
  
    let heightLeft = imgHeight;
    let position = 0; // Start position for drawing
  
    // Add the first page with content
    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  
    // Add subsequent pages if needed
    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft); // Adjust position for next slice
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
  
    // Add footer on each page
    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8); // Footer font size
      pdf.setTextColor(100); // Footer text color
      pdf.text('This report was generated with Lamemone ERP', 105, pdf.internal.pageSize.height - 10, {
        align: 'center'
      });
    }
  
    const timestamp = new Date().getTime();
    pdf.save(`report_${timestamp}.pdf`);
  };
  
  




  const handleDownloadPdfSender = async () => {

    const element = exportRef.current;
    const canvas = await html2canvas(element, { scale: 1, useCORS: true });
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 190;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const pageHeight = pdf.internal.pageSize.height - 20;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft);
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8);
      pdf.setTextColor(100);
      pdf.text(
        "This report was generated with Lamemone ERP",
        105,
        pdf.internal.pageSize.height - 10,
        { align: "center" }
      );
    }

    const timestamp = new Date().getTime();
    const fileName = `${timestamp}.pdf`;

    // Convert PDF to Blob
    const pdfBlob = pdf.output("blob");

    // Upload to Firebase Storage
    const storageRef = ref(storage, `reports/${fileName}`);
    await uploadBytes(storageRef, pdfBlob);

    // Get Download URL
    const downloadUrl = await getDownloadURL(storageRef);
    const elist = [];
    elist.push(manager.email);
    elist.push(tracker.email);

    if (emails.length > 0) {
      elist.push(...emails);
    }
    const uniqueEmails = [...new Set(elist)];
    const sender = userPersonalData.firstName + " " + userPersonalData.lastName;
    await sendEmailWithAttachment(uniqueEmails, fileName, downloadUrl, sender, selectedReport.safetyReportItem + " | " + selectedCompany.companyName + " | " + selectedSite.projectName);

    toast.success("הקובץ נשמר ונשלח בהצלחה!");

};





  
  



  return (
    <>
      <ToastContainer />
      <div className="row heading-container">
        <div className="col-lg-10"><h3>דו״חות בטיחות | {selectedCompany.companyName} | פרויקט {selectedSite.projectName}</h3></div>
        <div className="col-lg-2">
          <button style={{marginRight:10}} onClick={() => {handleModal('mail')}} className="btn btn-info"><RiMailSendLine size={30} color="#ffffff" /></button>
          <button style={{marginRight:10}} onClick={() => {handleModal('export')}} className="btn btn-danger"><FaRegFilePdf size={30} color="#ffffff" /></button>
        </div>
      </div>






      <div className="row" style={{ padding: 50 }}>
        
        <div className="row">
            <div className="col-lg-10">
                <h2>{selectedReport.safetyReportItem} | {selectedCompany.companyName} | {selectedSite.projectName}</h2>
                <div className="row">
                    <div className="col-lg-10">
                        <p className="company-name"><b>הופק בתאריך: </b>{moment(selectedReport.createdAt).format('DD/MM/YYYY')} | <b>גורם מנהל: </b>{manager.firstName} {manager.lastName} | <b>גורם מעקב: </b>{tracker.firstName} {tracker.lastName}</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-2" style={{background:priorityColor, paddingTop:24, borderRadius:10, textAlign:'center'}}>
                <p style={{color:'#fff', padding:0, margin:0, lineHeight:0}}>ציון כללי</p>
                <h1 className="score-title">{parseFloat(selectedReport.totalAverage).toFixed(0)}</h1>
            </div>
        </div>
        <div style={{marginTop:20}}></div>
        {
            selectedReport?.subCategories?.length > 0 && (<>
            {
                selectedReport.subCategories.map((subcat,index) => (
                  <>
                    <div className="row subcat-container" key={index}>
                        <div className="col-lg-10">
                            <p className="stext18">{subcat.safetyReportSubItem}</p>
                        </div>
                        <div className="col-lg-2">
                            <p className="stext18">ציון קטגוריה: <b>{subcat.score.toFixed(0)}</b></p>
                        </div>
                    </div> 



                    {
                        subcat?.sections?.length > 0 && (<>

                        <table class="table custom-table" style={{fontSize:19}}>
                        <thead>
                            <tr>
                                <th scope="col">קטגוריה</th>
                                <th scope="col">ציון מקסימלי</th>
                                <th scope="col">ציון שנרשם</th>
                                <th scope="col">ממצאים</th>
                            </tr>
                        </thead>
                        <tbody>

                        {
                            subcat.sections.map((section,i) => (<>
                            <tr key={i}>
                                <td>{section.safetyReportSubItemTitleName}</td>
                                <td>{section.safetyReportSubItemTitleScore}</td>
                                <td><b>{section?.sectionFinalScore?.toFixed(0)}</b></td>
                                <td>{section.findingItems ? section.findingItems.length : "0"}</td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                {
                                section.findingItems && (<>
                                {
                                    section.findingItems.length > 0 && (<>
                                        {
                                            section.findingItems.map((finding,j) => (
                                                <FindingItemSmall
                                                    key={j}
                                                    safetyReportsAreas={safetyReportsAreas}
                                                    finding={finding}
                                                />
                                            ))
                                        }
                                    </>)
                                }
                                </>)
                            }
                                </td>
                            </tr>
                            </>))
                        }

                        </tbody>
                        </table>
                        </>)
                    }
                  </> 
                ))
            }
            </>)
        }

      </div>



      <Modal show={showModal} dialogClassName="custom-modal" onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row" ref={exportRef} style={{ padding: 20 }}>
        
        
        <div className="row">

        <div className="col-lg-4">

        <div className="row">
                  <div className="col-lg-2">
                  <img src="/images/logo_black.png" style={{width:60}} />
                  </div>
                  <div className="col-lg-10">
                    <p className="companyInfo">
                      דוח בטיחות זה<br/>
                      הופק באמצעות<br/>
                      מערכת לממונה
                    </p>
                  </div>
                </div>


            
          </div>

          <div className="col-lg-4">
            {
              myCompany && myCompany.companyLogo ? (<>
                <div className="row">
                  <div className="col-lg-3">
                    <img src={myCompany.companyLogo} style={{width:80}} />
                  </div>
                  <div className="col-lg-9">
                    <p className="companyInfo">
                      <b>{myCompany.companyName}</b><br/>
                      {myCompany.companyAddress}, {myCompany.companyCity}<br/>
                      ח.פ. {myCompany.companyTaxId}<br/>
                      {myCompany.companyEmail} | {myCompany.companyPhone}
                    </p>
                  </div>
                </div>
              </>) : (<>
              
              </>)
            }
          </div>
          
          <div className="col-lg-4">
          {
              selectedCompany && selectedCompany.companyLogo ? (<>
                <div className="row">
                  <div className="col-lg-3">
                    <img src={selectedCompany.companyLogo} style={{width:80}} />
                  </div>
                  <div className="col-lg-9">
                    <p className="companyInfo">
                      <b>{selectedCompany.companyName}</b><br/>
                      {selectedCompany.companyAddress}, {selectedCompany.companyCity}<br/>
                      ח.פ. {selectedCompany.companyTaxId}<br/>
                      {selectedCompany.companyEmail} | {selectedCompany.companyPhone}
                    </p>
                  </div>
                </div>
              </>) : (<>
              
              </>)
            }
          </div>
        </div>



        <div className="row" style={{marginTop:50}}>
            <div className="col-lg-10">
                <h2>{selectedReport.safetyReportItem} | {selectedCompany.companyName} | {selectedSite.projectName}</h2>
                <div className="row">
                    <div className="col-lg-10">
                        <p className="company-name"><b>הופק בתאריך: </b>{moment(selectedReport.createdAt).format('DD/MM/YYYY')} | <b>גורם מנהל: </b>{manager.firstName} {manager.lastName} | <b>גורם מעקב: </b>{tracker.firstName} {tracker.lastName}</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-2" style={{background:priorityColor, paddingTop:24, borderRadius:10, textAlign:'center'}}>
                <p style={{color:'#fff', padding:0, margin:0, lineHeight:0}}>ציון כללי</p>
                <h1 className="score-title">{parseFloat(selectedReport.totalAverage).toFixed(0)}</h1>
            </div>
        </div>
        <div style={{marginTop:20}}></div>
        {
            selectedReport?.subCategories?.length > 0 && (<>
            {
                selectedReport.subCategories.map((subcat,index) => (
                  <>
                    <div className="row subcat-container" key={index}>
                        <div className="col-lg-10">
                            <p className="stext18">{subcat.safetyReportSubItem}</p>
                        </div>
                        <div className="col-lg-2">
                            <p className="stext18">ציון קטגוריה: <b>{subcat.score.toFixed(0)}</b></p>
                        </div>
                    </div> 



                    {
                        subcat?.sections?.length > 0 && (<>

                        <table class="table custom-table" style={{fontSize:19}}>
                        <thead>
                            <tr>
                                <th scope="col">קטגוריה</th>
                                <th scope="col">ציון מקסימלי</th>
                                <th scope="col">ציון שנרשם</th>
                                <th scope="col">ממצאים</th>
                            </tr>
                        </thead>
                        <tbody>

                        {
                            subcat.sections.map((section,i) => (<>
                            <tr key={i}>
                                <td>{section.safetyReportSubItemTitleName}</td>
                                <td>{section.safetyReportSubItemTitleScore}</td>
                                <td><b>{section?.sectionFinalScore?.toFixed(0)}</b></td>
                                <td>{section.findingItems ? section.findingItems.length : "0"}</td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                {
                                section.findingItems && (<>
                                {
                                    section.findingItems.length > 0 && (<>
                                        {
                                            section.findingItems.map((finding,j) => (
                                                <FindingItemSmall
                                                    key={j}
                                                    safetyReportsAreas={safetyReportsAreas}
                                                    finding={finding}
                                                />
                                            ))
                                        }
                                    </>)
                                }
                                </>)
                            }
                                </td>
                            </tr>
                            </>))
                        }

                        </tbody>
                        </table>
                        </>)
                    }
                  </> 
                ))
            }
            </>)
        }

      </div>

        </Modal.Body>
        <Modal.Footer>
        {
            isExport ? (
              <button onClick={handleDownloadPdf} className="btn btn-success btn-lg">שמירת קובץ</button>
            ) : (
              <>
              <div style={{ width: 600 }}>
                  <div
                    className="form-control"
                    style={{
                      minHeight: 45,
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      padding: 5,
                    }}
                  >
                    {emails.map((email, index) => (
                      <span key={index} className="badge bg-primary me-2 mb-1">
                        {email}
                        <button
                          type="button"
                          className="btn-close btn-close-white ms-2"
                          aria-label="Remove"
                          onClick={() => removeEmail(email)}
                          style={{ fontSize: "0.6rem", marginLeft: 8 }}
                        ></button>
                      </span>
                    ))}
                    <input
                      type="text"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder="הוסף מייל ולחץ Enter או Tab"
                      style={{
                        flex: 1,
                        minWidth: 150,
                        border: "none",
                        outline: "none",
                      }}
                    />
                  </div>
                </div>
                <button onClick={handleDownloadPdfSender} className="btn btn-success btn-lg">שליחה לרשימת תפוצה</button>
              </>
              
            )
          }
        </Modal.Footer>
      </Modal>






      
    </>
  );
};

export default ReportDetails;
