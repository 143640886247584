import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { MdDelete } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import { FaAngleLeft } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { useParams, useNavigate } from "react-router-dom";
import useDashboardStore from "../../store/useDashboardStore";
import { database } from "../../services/firebase-config"; // Ensure your Firestore instance is imported
import { doc, updateDoc } from "firebase/firestore";

const DebriefingItem = (props) => {
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState(false);

    const updateReportStatus = useDashboardStore((state) => state.updateReportStatus);


    const handleDelete = () => {
        setShowConfirmation(true);
      };
      const handleConfirmDelete = () => {
        setShowConfirmation(false);
        onDelete();
      };
      const handleCancelDelete = () => {
        setShowConfirmation(false);
      };
      const onDelete = async () => {
        props.removeReport(props.report);
      };
      const handleLink = async(id) => {
        navigate(`/debriefing-edit/${id}`);
      }





      const onToggleStatus = async () => {
        try {
          const reportRef = doc(database, "debriefing_reports", props.report.id); // Firestore document reference
          const newStatus = !props.report.isActive; // Toggle the boolean value
    
          await updateDoc(reportRef, {
            isActive: newStatus,
          });
    
          updateReportStatus(props.report.id, newStatus); // Update Zustand store
        } catch (error) {
          toast.error("Failed to update status");
          console.error(error);
        }
      };




  return (
    <>
    <div className="col-lg-6">
        <h6>{props.report.debriefType} | שם העובד: {props.report.injuredEmployee.firstName} {props.report.injuredEmployee.lastName}</h6>
        <p><b>תאריך התאונה: </b>{moment(props.report.reportDate).format("DD/MM/YYYY")} בשעה: {props.report.reportHour}, <b>איבר שנפגע: </b>{props.report.injuredLimb}</p>
        <p><b>קבצים מצורפים:</b> {props.report.reportFiles.length}, <b>עדים לאירוע:</b> {props.report.witnesses.length}, <b>רשימת תפוצה:</b> {props.report.mailingList.length}</p>

        {
          props.report.extraWitnesses && (<p><b>עדים נוספים: </b>{props.report.extraWitnesses}</p>)
        }

    </div>
    <div className="col-lg-2">
        {
            props.report.isReported === 'דווח' ? (<>
            <FaCheckCircle color="#00BD9D" size={40} />
            <p>התחקיר דווח למשרד העבודה <a href={props.report.linkToReport} target="_blank">לינק</a></p>
            </>) : (<>
            <MdOutlineRadioButtonUnchecked color="#acd4da" size={40} />
            <p>התחקיר לא דווח למשרד העבודה</p>
            </>)
        }
    </div>
    <div className="col-lg-2" onClick={onToggleStatus} style={{ cursor: "pointer" }}>
        {!props.report.isActive ? (
          <>
            <FaCheckCircle color="#00BD9D" size={40} />
            <p>התחקיר נסגר</p>
          </>
        ) : (
          <>
            <MdOutlineRadioButtonUnchecked color="#acd4da" size={40} />
            <p>התחקיר פעיל</p>
          </>
        )}
      </div>
    <div className="col-lg-2" style={{justifyContent:'space-between'}}>
        <button onClick={handleDelete} className="btn btn-danger" style={{marginTop:14}}><MdDelete color="#ffffff" size={20} /></button>
        {showConfirmation && (
                <div style={{ flexDirection: "row", marginTop: 10 }}>
                  <p>
                    אישור מחיקה{" "}
                    <button className="btn btn-danger" onClick={handleConfirmDelete}>מחיקה</button>{" "}
                    <button className="btn btn-success" onClick={handleCancelDelete}>ביטול</button>
                  </p>
                </div>
              )}

        <button onClick={() => {handleLink(props.report.id)}} className="btn btn-success" style={{marginTop:14, marginRight:12}}><FaAngleLeft color="#ffffff" size={20} /></button>
    </div>
    </>
  );
};

export default DebriefingItem;
