import React, { useState, useEffect, useCallback } from "react";
import { auth, database } from "../../services/firebase-config";
import {
  requestDataByAssociate,
  getAccountDetails,
} from "../../services/dbHelper";
import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import SafetyAreaItem from "./components/SafetyAreaItem";
import useDashboardStore from "../../store/useDashboardStore";
import { requestSuperData } from "../../store/dashboardHook";
import SafetyAreaItemAdmin from "./components/SafetyAreaItemAdmin";
import { FaQuestionCircle } from "react-icons/fa";

const SafetyReportsAreas = () => {
  const { userPersonalData } = useDashboardStore();
  const [isLoading, setIsLoading] = useState(false);
  const [myAccount, setMyAccount] = useState(false);
  const [safetyReportAreaItem, setSafetyReportAreaItem] = useState("");
  const [safetyReportsArea, setSafetyReportsArea] = useState([]);
  const [safetyReportsAreaAdmin, setSafetyReportsAreaAdmin] = useState([]);
  const [adminCategory, setAdminCategory] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const account = await getAccountDetails(auth.currentUser.uid);

      const safetyReportsAreasArr = await requestDataByAssociate(
        account.associateId,
        "safetyReportsAreas"
      );

      const safetyReportsAreasArrAdmin = await requestSuperData(
        "safetyReportsAreas"
      );

      const safetyReportsAreasArrfiltered = safetyReportsAreasArr.filter(
        (x) => x.adminCategory === false
      );
      const safetyReportsAreasArrAdminFiltered =
        safetyReportsAreasArrAdmin.filter((x) => x.adminCategory === true);

      setSafetyReportsArea(safetyReportsAreasArrfiltered);
      setSafetyReportsAreaAdmin(safetyReportsAreasArrAdminFiltered);

      setMyAccount(account);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    loadData();
  }, []);

  const addSafetyReportArea = async () => {
    if (safetyReportAreaItem !== "") {
      try {
        await addDoc(collection(database, "safetyReportsAreas"), {
          associateId: myAccount.associateId,
          safetyReportAreaItem: safetyReportAreaItem,
          adminCategory: adminCategory,
        });
        loadData();
        setSafetyReportAreaItem("");
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error("נא למלא את שם תחום הבטיחות");
    }
  };
  const editItem = async (id, title) => {
    const trackingRef = doc(database, "safetyReportsAreas", id);
    try {
      await updateDoc(trackingRef, {
        safetyReportAreaItem: title,
      });
      loadData();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const deleteItem = async (id) => {
    const isConfirmed = window.confirm("האם למחוק את הקטגוריה לצמיתות?");
    if (isConfirmed) {
      try {
        console.log("ID: " + id);
        await deleteDoc(doc(database, "safetyReportsAreas", id)).then(
          (result) => {
            loadData();
          }
        );
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const addTemplateToMyList = async (item) => {
    try {
      await addDoc(collection(database, "safetyReportsAreas"), {
        associateId: myAccount.associateId,
        safetyReportAreaItem: item.safetyReportAreaItem,
        adminCategory: false,
        templateId: item.id,
      });
      loadData();
      setSafetyReportAreaItem("");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const [showTooltip1, setShowTooltip1] = useState(false);
  const toggleTooltip1 = () => setShowTooltip1(!showTooltip1);

  return (
    <>
      <div className="row heading-container">
        <div className="col-lg-10">

          <h3>תחומי בטיחות</h3>
        </div>
        <div className="col-lg-2 heading-btn-container"></div>
      </div>
      <ToastContainer />

      <div className="row" style={{ padding: 50 }}>
        <div className="col-lg-4">
          <h6>תחומי בטיחות <FaQuestionCircle onClick={toggleTooltip1} style={{ cursor: 'pointer' }} size={20} color="#0e6da8" />
          {showTooltip1 && (
            <div style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', background: 'white' }}>
              <p className="tooltip-text">הסבר אודות תחומי בטיחות</p>
            </div>
          )}</h6>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-9">
              <input
                value={safetyReportAreaItem}
                onChange={(e) => setSafetyReportAreaItem(e.target.value)}
                type="text"
                placeholder="שם תחום בטיחות"
                className="form-control"
              />
            </div>
            <div className="col-lg-2">
              <button onClick={addSafetyReportArea} className="btn btn-success">
                הוספה
              </button>
            </div>
            <div className="col-lg-12">
              {userPersonalData.role === "superadmin" && (
                <>
                  <input
                    onChange={(e) => setAdminCategory(e.target.checked)}
                    checked={adminCategory}
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                  />{" "}
                  <label className="form-check-label">
                    הוסף קטגוריה לכל המשתמשים
                  </label>
                </>
              )}
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-12">
              {isLoading ? (
                <>
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              ) : (
                <>
                  <p style={{ marginBottom: 20 }}>
                    <b>הקטגוריות שלי</b>
                  </p>
                  {safetyReportsArea.length > 0 ? (
                    <>
                      {safetyReportsArea.map((item) => (
                        <SafetyAreaItem
                          key={item.id}
                          item={item}
                          editItem={editItem}
                          deleteItem={deleteItem}
                        />
                      ))}
                    </>
                  ) : (
                    <p>לא קיימים תחומי דוחות במערכת</p>
                  )}

                  <p style={{ marginBottom: 12, marginTop: 20 }}>
                    <b>קטגוריות מערכת</b>
                  </p>
                  {safetyReportsAreaAdmin.length > 0 ? (
                    <>
                      {safetyReportsAreaAdmin
                        .filter(
                          (adminItem) =>
                            !safetyReportsArea.some(
                              (myItem) => myItem.templateId === adminItem.id
                            )
                        )
                        .map((item) => (
                          <SafetyAreaItemAdmin
                            key={item.id}
                            addTemplateToMyList={addTemplateToMyList}
                            role={userPersonalData.role}
                            item={item}
                            editItem={editItem}
                            deleteItem={deleteItem}
                          />
                        ))}
                    </>
                  ) : (
                    <p>לא קיימים תחומי דוחות במערכת</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4"></div>
        <div className="col-lg-4"></div>
      </div>
    </>
  );
};

export default SafetyReportsAreas;
