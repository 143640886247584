import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import useDashboardStore from "../../store/useDashboardStore";
import { FaRegFilePdf, FaEdit } from "react-icons/fa";
import { Modal, Table } from "react-bootstrap";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import DebriefingForm from "./DebriefingForm";
import { auth, database, storage } from "../../services/firebase-config";
import { doc, updateDoc } from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { sendEmailWithAttachment } from "../../services/emailService";
import { RiMailSendLine } from "react-icons/ri";

const DebriefingEdit = (props) => {
  const { id } = useParams();
  const {
    userPersonalData,
    selectedCompany,
    myCompany,
    companies,
    employees,
    periodics,
    addDebriefingReport,
    debriefingReports,
    updateDebriefingReport,
    updateMachineReport,
    removeDebriefingReportById,
    myPlan,
  } = useDashboardStore();

  const [report, setReport] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isExport, setIsExport] = useState(true);

  useEffect(() => {
    const selectedReport = debriefingReports.find((x) => x.id === id);
    setReport(selectedReport || null); // Ensure it's never undefined
  }, [debriefingReports, id]);

  const handleModal = async (action) => {
    if (action === "mail") {
      setIsExport(false);
    } else {
      setIsExport(true);
    }
    setShowModal(true);
  };

  
  const exportRef = React.useRef();
  const [input, setInput] = useState("");
  const [emails, setEmails] = useState([]);

  const handleKeyDown = (e) => {
    if ((e.key === "Enter" || e.key === "Tab") && input.trim() !== "") {
      e.preventDefault();
      const newEmail = input.trim();

      if (validateEmail(newEmail) && !emails.includes(newEmail)) {
        const updated = [...emails, newEmail];
        setEmails(updated);
      }
      setInput("");
    }
  };

  const removeEmail = (emailToRemove) => {
    const updated = emails.filter((email) => email !== emailToRemove);
    setEmails(updated);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleDownloadPdf = async () => {
    if (fileNameReq != "") {
      const element = exportRef.current;
      const canvas = await html2canvas(element, { scale: 1, useCORS: true });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const pageHeight = pdf.internal.pageSize.height - 20;

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = -(imgHeight - heightLeft);
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      const numPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= numPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        pdf.setTextColor(100);
        pdf.text(
          "This report was generated with Lamemone ERP",
          105,
          pdf.internal.pageSize.height - 10,
          {
            align: "center",
          }
        );
      }

      const timestamp = new Date().getTime();
      pdf.save(`${fileNameReq}.pdf`);
    } else {
      toast.error("יש למלא את שם הקובץ לפני שמירה");
    }
  };

  const [fileNameReq, setFileNameReq] = useState("");
  const [per1, setPer1] = useState("");
  const [per2, setPer2] = useState("");
  const [per3, setPer3] = useState("");
  const [editView, setEditView] = useState(false);

  const [employeesArr, setEmployeesArr] = useState([]);
  const [employeesMailingArr, setEmployeesMailingArr] = useState([]);
  const [reportsArr, setReportsArr] = useState([]);
  const [reportsArrOrig, setReportsArrOrig] = useState([]);

  useEffect(() => {
    const debriefingReportsList = debriefingReports
      .filter((x) => x.associateCompanyId === selectedCompany.id)
      .sort((a, b) => a.reportDate - b.reportDate);

    const employeesList = employees
      .filter((x) => x.associateCompany === selectedCompany.id)
      .sort((a, b) => {
        const firstNameComparison = a.firstName.localeCompare(b.firstName);
        return firstNameComparison !== 0
          ? firstNameComparison
          : a.lastName.localeCompare(b.lastName);
      });

    setReportsArr(debriefingReportsList);
    setReportsArrOrig(debriefingReportsList);

    setEmployeesArr(employeesList);
    setEmployeesMailingArr(employeesList);
  }, [selectedCompany, debriefingReports]);

  useEffect(() => {
    if (report && employees.length > 0) {
      setPer1(
        report.recommendations_p1?.recommendations_p1_person
          ? employees.find(
              (x) =>
                x.id === report.recommendations_p1.recommendations_p1_person
            ) || ""
          : ""
      );
      setPer2(
        report.recommendations_p2?.recommendations_p2_person
          ? employees.find(
              (x) =>
                x.id === report.recommendations_p2.recommendations_p2_person
            ) || ""
          : ""
      );
      setPer3(
        report.recommendations_p3?.recommendations_p3_person
          ? employees.find(
              (x) =>
                x.id === report.recommendations_p3.recommendations_p3_person
            ) || ""
          : ""
      );
    }
  }, [report, employees]);

  const updateDebriefAction = async (
    reportId,
    debriefType,
    isReported,
    reportDate,
    injuredLimb,
    daysOfAbsence,
    description,
    personDescription,
    machineDescription,
    taskDescription,
    envDescription,
    proceduresDescription,
    conclusions,
    recommendations_p1_desc,
    recommendations_p2_desc,
    recommendations_p3_desc,
    recommendations_p1_person,
    recommendations_p2_person,
    recommendations_p3_person,
    recommendations_p1_date,
    recommendations_p2_date,
    recommendations_p3_date,
    injuredEmployee,
    linkToReport,
    extraWitnesses,
    reportHour,
    selectedEmployees,
    selectedMailingEmployees,
    pdfPreviewUrls
  ) => {
    try {
      const reportRef = doc(database, "debriefing_reports", reportId);

      // Handle new PDF uploads if provided
      let updatedDownloadUrls = [];
      if (pdfPreviewUrls.length > 0) {
        const uploadTasks = await Promise.all(
          pdfPreviewUrls.map((file) =>
            uploadBytesResumable(ref(storage, `files/${file.name}`), file)
          )
        );

        updatedDownloadUrls = await Promise.all(
          uploadTasks.map((task) => getDownloadURL(task.ref))
        );
      }

      // Find injured employee details
      const ie = employeesArr.find((x) => x.id === injuredEmployee);

      // Updated report object
      const updatedReport = {
        debriefType,
        isReported,
        reportDate: new Date(reportDate).getTime(),
        reportHour,
        injuredLimb,
        daysOfAbsence,
        description,
        personDescription,
        machineDescription,
        taskDescription,
        envDescription,
        proceduresDescription,
        conclusions,
        injuredEmployee: ie,
        linkToReport,
        extraWitnesses,
        witnesses: selectedEmployees,
        mailingList: selectedMailingEmployees,
        recommendations_p1: {
          recommendations_p1_date: new Date(recommendations_p1_date).getTime(),
          recommendations_p1_desc,
          recommendations_p1_person,
        },
        recommendations_p2: {
          recommendations_p2_date: new Date(recommendations_p2_date).getTime(),
          recommendations_p2_desc,
          recommendations_p2_person,
        },
        recommendations_p3: {
          recommendations_p3_date: new Date(recommendations_p3_date).getTime(),
          recommendations_p3_desc,
          recommendations_p3_person,
        },
      };

      // Only update reportFiles if new PDFs were uploaded
      if (updatedDownloadUrls.length > 0) {
        updatedReport.reportFiles = updatedDownloadUrls;
      }

      // Update Firestore document
      await updateDoc(reportRef, updatedReport);

      // Update Zustand store
      updateDebriefingReport(reportId, updatedReport);

      toast.success("התחקיר עודכן בהצלחה");
    } catch (error) {
      console.error("Error updating report: ", error.message);
      toast.error("עדכון התחקיר נכשל");
    } finally {
      console.log("UPDATED");
    }
  };

  const handleDownloadPdfSender = async () => {
    const element = exportRef.current;
    const canvas = await html2canvas(element, { scale: 1, useCORS: true });
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const imgWidth = 190;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const pageHeight = pdf.internal.pageSize.height - 20;
    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft > 0) {
      position = -(imgHeight - heightLeft);
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    const numPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= numPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(8);
      pdf.setTextColor(100);
      pdf.text(
        "This report was generated with Lamemone ERP",
        105,
        pdf.internal.pageSize.height - 10,
        { align: "center" }
      );
    }

    const timestamp = new Date().getTime();
    const fileName = `${timestamp}.pdf`;

    // Convert PDF to Blob
    const pdfBlob = pdf.output("blob");

    // Upload to Firebase Storage
    const storageRef = ref(storage, `reports/${fileName}`);
    await uploadBytes(storageRef, pdfBlob);

    // Get Download URL
    const downloadUrl = await getDownloadURL(storageRef);

    // יצירת רשימת תפוצה אחת
    const elist = [];

    // מקור ראשון: רשימת התפוצה מתוך הדוח
    if (report?.mailingList?.length > 0) {
      report.mailingList.forEach((item) => {
        if (item.email) {
          elist.push(item.email);
        }
      });
    }

    // מקור שני: שלושת בעלי התפקידים (אם קיימים)
    const personData1 = report?.recommendations_p1?.recommendations_p1_person;
    const personData2 = report?.recommendations_p2?.recommendations_p2_person;
    const personData3 = report?.recommendations_p3?.recommendations_p3_person;

    const checkAndAddEmail = (personData, label) => {
      if (personData && typeof personData === "string") {
        const emp = employeesArr.find((x) => x.id === personData);
        if (emp?.email) {
          elist.push(emp.email);
        } else {
          console.log(`${label} - Employee Not Found`);
        }
      } else {
        console.log(`${label} - NOT EXIST`);
      }
    };

    checkAndAddEmail(personData1, "Person 1");
    checkAndAddEmail(personData2, "Person 2");
    checkAndAddEmail(personData3, "Person 3");

    // מקור שלישי: כתובות מייל מה-input
    if (emails.length > 0) {
      elist.push(...emails);
    }

    // הסרת כפילויות
    const uniqueEmails = [...new Set(elist)];

    // Apply function to all three cases
    checkAndAddEmail(personData1, "Person 1");
    checkAndAddEmail(personData2, "Person 2");
    checkAndAddEmail(personData3, "Person 3");

    const sender = userPersonalData.firstName + " " + userPersonalData.lastName;
    await sendEmailWithAttachment(
      uniqueEmails,
      fileName,
      downloadUrl,
      sender,
      "תחקיר תאונת עבודה"
    );

    toast.success("הקובץ נשמר ונשלח בהצלחה!");
  };

  return (
    <>
      <ToastContainer />
      <div className="row heading-container">
        <div className="col-lg-10">
          <h3>
            תחקיר {report?.debriefType} | {report?.injuredEmployee?.firstName}{" "}
            {report?.injuredEmployee?.lastName}
          </h3>
        </div>
        <div className="col-lg-2">
          {myPlan.actions && (
            <>
              <button
                style={{ marginRight: 10 }}
                onClick={() => {
                  handleModal("mail");
                }}
                className="btn btn-info"
              >
                <RiMailSendLine size={30} color="#ffffff" />
              </button>
              <button
                style={{ marginRight: 10 }}
                onClick={handleModal}
                className="btn btn-danger"
              >
                <FaRegFilePdf size={30} color="#ffffff" />
              </button>
            </>
          )}
          <button
            style={{ marginRight: 10 }}
            onClick={() => {
              setEditView(!editView);
            }}
            className="btn btn-success"
          >
            <FaEdit size={30} color="#ffffff" />
          </button>
        </div>
      </div>
      <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 14 }}>
        {editView ? (
          <>
            <DebriefingForm
              updateDebriefAction={updateDebriefAction}
              employees={employees}
              selectedCompany={selectedCompany}
              report={report}
              employeesArr={employeesArr}
              debriefingReports={debriefingReports}
            />
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-4">
                <h5>פרטי העובד</h5>
                <br />
                {report?.injuredEmployee?.avatar && (
                  <img
                    src={report?.injuredEmployee?.avatar}
                    style={{ width: 180 }}
                  />
                )}
                <p style={{ marginTop: 20 }}>
                  <b>שם העובד: </b>
                  {report?.injuredEmployee?.firstName}{" "}
                  {report?.injuredEmployee?.lastName}
                  <br />
                  <b>מזהה מערכת: </b>
                  {report?.injuredEmployee?.id}
                  <br />
                  <b>תעודת זהות: </b>
                  {report?.injuredEmployee?.employeeId}
                  <br />
                  <b>מספר עובד: </b>
                  {report?.injuredEmployee?.employeeNumber}
                  <br />
                  <b>תפקיד: </b>
                  {report?.injuredEmployee?.position}
                  <br />
                  <b>טלפון: </b>
                  {report?.injuredEmployee?.phone}
                  <br />
                  <b>דואר אלקטרוני: </b>
                  {report?.injuredEmployee?.email}
                  <br />
                  <b>התווסף בתאריך: </b>
                  {moment(report?.injuredEmployee?.createdAt).format(
                    "DD/MM/YYYY"
                  )}
                  <br />
                  <b>כתובת: </b>
                  {report?.injuredEmployee?.address}
                  <br />
                  <b>קופת חולים: </b>
                  {report?.injuredEmployee?.hospitality}
                  <br />
                  <b>מחלקה: </b>
                  {report?.injuredEmployee?.company}
                  <br />
                </p>
              </div>
              <div className="col-lg-4">
                <h5>פרטי האירוע</h5>
                <br />
                <b>מזהה מערכת: </b>
                {report?.id}
                <br />
                <b>סוג האירוע: </b>
                {report?.debriefType}
                <br />
                <b>תאריך הוספת התחקיר: </b>
                {moment(report?.createdAt).format("DD/MM/YYYY")}
                <br />
                <b>תאריך האירוע: </b>
                {moment(report?.reportDate).format("DD/MM/YYYY")}
                <br />
                <b>שעה: </b>
                {report?.reportHour}
                <br />
                <b>איבר שנפגע: </b>
                {report?.injuredLimb}
                <br />
                <b>ימי היעדרות: </b>
                {report?.daysOfAbsence}
                <br />
                <b>תיאור האירוע: </b>
                {report?.description}
                <br />
                <b>דווח למשרד העבודה: </b>
                {report?.isReported}
                <br />
                <b>קישור לדווח: </b>
                <a href={report?.linkToReport} target="_blank">
                  הקלק לפתיחת הקישור
                </a>
                <br />

                <b>עדים לאירוע: </b>
                <br />
                {report?.witnesses?.length > 0 ? (
                  <>
                    {report?.witnesses.map((item) => (
                      <>
                        {item.firstName} {item.lastName}
                        <br />
                      </>
                    ))}
                  </>
                ) : (
                  <>לא היו עדים לאירוע</>
                )}
                <br />

                {report?.extraWitnesses && (
                  <>
                    <p>
                      <b>עדים נוספים: </b>
                      {report.extraWitnesses}
                    </p>
                    <br />
                  </>
                )}

                <b>קבצים משוייכים: </b>
                <br />
                {report?.reportFiles?.length > 0 ? (
                  <>
                    {report?.reportFiles.map((item) => (
                      <>
                        <a href={item} target="_blank">
                          {item}
                        </a>
                        <br />
                      </>
                    ))}
                  </>
                ) : (
                  <>לא קיימים קבצים</>
                )}
                <br />
                {report?.conclusions ? (
                  <>
                    <b>מסקנות: </b>
                    <br />
                    {report?.conclusions}
                    <br />
                  </>
                ) : (
                  <>
                    <b>
                      מסקנות:
                      <br />
                      לא נכתבו מסקנות לאירוע זה
                    </b>
                  </>
                )}

                <br />
                <br />
                {report?.personDescription && (
                  <>
                    <b>
                      אדם: פרוט דברים שהאדם ביצע גרמו לתאונה, כולל שמות. ציוד
                      מגן אישי, הדרכה, הסמכה, ותק:{" "}
                    </b>
                    <br />
                    {report?.personDescription}
                    <br />
                  </>
                )}
                {report?.machineDescription && (
                  <>
                    <b>
                      מכונה: פרוט מכונות או חלקי מכונה שהיו מעורבות בתאונה, האם
                      המכונה נבדקה, פורק או היה חסר מיגון וכו':{" "}
                    </b>
                    <br />
                    {report?.machineDescription}
                    <br />
                  </>
                )}
                {report?.taskDescription && (
                  <>
                    <b>
                      משימה: איזו משימה ביצע העובד בעת התאונה, ואיזו היה צריך
                      לבצע :{" "}
                    </b>
                    <br />
                    {report?.taskDescription}
                    <br />
                  </>
                )}
                {report?.envDescription && (
                  <>
                    <b>
                      תנאי סביבה: ארגונומיה, סדר וניקיון, צפיפות, מפגעים
                      וסיכונים, ועוד:{" "}
                    </b>
                    <br />
                    {report?.envDescription}
                    <br />
                  </>
                )}
                {report?.proceduresDescription && (
                  <>
                    <b>
                      נהלים: האם ישנם נהלים, הוראות עבודה, הדרכה, טפסים למילוי,
                      עבודה בניגוד לנהלים:{" "}
                    </b>
                    <br />
                    {report?.proceduresDescription}
                    <br />
                  </>
                )}
              </div>
              <div className="col-lg-4">
                <h5>תפוצה והמשך טיפול</h5>
                <br />

                {per1 && (
                  <>
                    <p>
                      <b>
                        {per1.firstName} {per1.lastName} - {per1.position}
                      </b>
                      <br />
                      {report?.recommendations_p1?.recommendations_p1_desc}
                      <br />
                      תאריך יעד לביצוע:{" "}
                      {moment(
                        report?.recommendations_p1?.recommendations_p1_date
                      ).format("DD/MM/YYYY")}
                    </p>
                    <br />
                  </>
                )}

                {per2 && (
                  <>
                    <p>
                      <b>
                        {per2.firstName} {per2.lastName} - {per2.position}
                      </b>
                      <br />
                      {report?.recommendations_p2?.recommendations_p2_desc}
                      <br />
                      תאריך יעד לביצוע:{" "}
                      {moment(
                        report?.recommendations_p2?.recommendations_p2_date
                      ).format("DD/MM/YYYY")}
                    </p>
                    <br />
                  </>
                )}

                {per3 && (
                  <>
                    <p>
                      <b>
                        {per3.firstName} {per3.lastName} - {per3.position}
                      </b>
                      <br />
                      {report?.recommendations_p3?.recommendations_p3_desc}
                      <br />
                      תאריך יעד לביצוע:{" "}
                      {moment(
                        report?.recommendations_p3?.recommendations_p3_date
                      ).format("DD/MM/YYYY")}
                    </p>
                    <br />
                  </>
                )}
                <br />
                <p>
                  <b>רשימת תפוצה</b>
                </p>
                {report?.mailingList?.length > 0 && (
                  <>
                    {report.mailingList.map((item) => (
                      <>
                        <p>
                          שם המקבל: {item.firstName} {item.lastName},{" "}
                          {item.position}
                        </p>
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <Modal
          show={showModal}
          dialogClassName="custom-modal"
          onHide={() => setShowModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>ייצוא דוח ל-PDF</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row" ref={exportRef} style={{ padding: 50 }}>
              <div className="row">
                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-2">
                      <img src="/images/logo_black.png" style={{ width: 60 }} />
                    </div>
                    <div className="col-lg-10">
                      <p className="companyInfo">
                        דוח בטיחות זה
                        <br />
                        הופק באמצעות
                        <br />
                        מערכת לממונה
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  {myCompany && myCompany.companyLogo ? (
                    <>
                      <div className="row">
                        <div className="col-lg-3">
                          <img
                            src={myCompany.companyLogo}
                            style={{ width: 80 }}
                          />
                        </div>
                        <div className="col-lg-9">
                          <p className="companyInfo">
                            <b>{myCompany.companyName}</b>
                            <br />
                            {myCompany.companyAddress}, {myCompany.companyCity}
                            <br />
                            ח.פ. {myCompany.companyTaxId}
                            <br />
                            {myCompany.companyEmail} | {myCompany.companyPhone}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="col-lg-4">
                  {selectedCompany && selectedCompany.companyLogo ? (
                    <>
                      <div className="row">
                        <div className="col-lg-3">
                          <img
                            src={selectedCompany.companyLogo}
                            style={{ width: 80 }}
                          />
                        </div>
                        <div className="col-lg-9">
                          <p className="companyInfo">
                            <b>{selectedCompany.companyName}</b>
                            <br />
                            {selectedCompany.companyAddress},{" "}
                            {selectedCompany.companyCity}
                            <br />
                            ח.פ. {selectedCompany.companyTaxId}
                            <br />
                            {selectedCompany.companyEmail} |{" "}
                            {selectedCompany.companyPhone}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="row" style={{ marginTop: 50, marginBottom: 50 }}>
                <div className="col-lg-12">
                  <h2>תחקיר {report?.debriefType}</h2>
                </div>
              </div>

              <div className="row" style={{ fontSize: 24 }}>
                <div className="col-lg-4 tcol">
                  <b>שם העובד: </b>
                  {report?.injuredEmployee?.firstName}{" "}
                  {report?.injuredEmployee?.lastName}
                </div>
                <div className="col-lg-4 tcol">
                  <b>תעודת זהות: </b>
                  {report?.injuredEmployee?.employeeId}
                </div>
                <div className="col-lg-4 tcol">
                  <b>טלפון: </b>
                  {report?.injuredEmployee?.phone}
                </div>

                <div className="col-lg-4 tcol">
                  <b>מחלקה: </b>
                  {report?.injuredEmployee?.company}
                </div>
                <div className="col-lg-4 tcol">
                  <b>תפקיד: </b>
                  {report?.injuredEmployee?.position}
                </div>
                <div className="col-lg-4 tcol">
                  <b>תאריך האירוע: </b>
                  {moment(report?.reportDate).format("DD/MM/YYYY")}
                </div>

                <div className="col-lg-4 tcol">
                  <b>שעת האירוע: </b>
                  {report?.reportHour}
                </div>
                <div className="col-lg-4 tcol">
                  <b>איבר שנפגע: </b>
                  {report?.injuredLimb}
                </div>
                <div className="col-lg-4 tcol">
                  <b>ימי היעדרות: </b>
                  {report?.daysOfAbsence}
                </div>

                <div className="col-lg-12 tcol">
                  <b>עדים לאירוע: </b>
                  {report?.witnesses?.length > 0 ? (
                    <>
                      {report?.witnesses.map((item) => (
                        <>
                          {item.firstName} {item.lastName},{" "}
                        </>
                      ))}
                    </>
                  ) : (
                    <>לא היו עדים לאירוע</>
                  )}
                </div>

                <div className="col-lg-12 tcol">
                  <b>עדים נוספים: </b>
                  {report?.extraWitnesses && <>{report.extraWitnesses}</>}
                </div>

                <div className="col-lg-12 tcol">
                  <b>תיאור פרטי התאונה: </b>
                  <br />
                  {report?.description}
                </div>

                <div className="col-lg-4 tcol">
                  <b>
                    אדם: פרוט דברים שהאדם ביצע גרמו לתאונה, כולל שמות. ציוד מגן
                    אישי, הדרכה, הסמכה, ותק:{" "}
                  </b>
                </div>
                <div className="col-lg-8 tcol">{report?.personDescription}</div>

                <div className="col-lg-4 tcol">
                  <b>
                    מכונה: פרוט מכונות או חלקי מכונה שהיו מעורבות בתאונה, האם
                    המכונה נבדקה, פורק או היה חסר מיגון וכו':{" "}
                  </b>
                </div>
                <div className="col-lg-8 tcol">
                  {report?.machineDescription}
                </div>

                <div className="col-lg-4 tcol">
                  <b>
                    משימה: איזו משימה ביצע העובד בעת התאונה, ואיזו היה צריך לבצע
                    :{" "}
                  </b>
                </div>
                <div className="col-lg-8 tcol">{report?.taskDescription}</div>

                <div className="col-lg-4 tcol">
                  <b>
                    תנאי סביבה: ארגונומיה, סדר וניקיון, צפיפות, מפגעים וסיכונים,
                    ועוד:{" "}
                  </b>
                </div>
                <div className="col-lg-8 tcol">{report?.envDescription}</div>

                <div className="col-lg-4 tcol">
                  <b>
                    נהלים: האם ישנם נהלים, הוראות עבודה, הדרכה, טפסים למילוי,
                    עבודה בניגוד לנהלים:{" "}
                  </b>
                </div>
                <div className="col-lg-8 tcol">
                  {report?.proceduresDescription}
                </div>

                <div className="col-lg-12 tcol">
                  <b>סיכום ומסקנות: </b>
                  <br />
                  {report?.conclusions}
                </div>

                <div className="col-lg-6 tcol">
                  <b>המלצות לתיקון / שיפור - פעולה מתקנת או מונעת</b>
                </div>
                <div className="col-lg-3 tcol">
                  <b>אחראי לביצוע</b>
                </div>
                <div className="col-lg-3 tcol">
                  <b>תאריך יעד</b>
                </div>

                <div className="col-lg-6 tcol">
                  {report?.recommendations_p1?.recommendations_p1_desc}
                </div>
                <div className="col-lg-3 tcol">
                  {per1.firstName} {per1.lastName} - {per1.position}
                </div>
                <div className="col-lg-3 tcol">
                  {per1 && (
                    <>
                      תאריך יעד לביצוע:{" "}
                      {moment(
                        report?.recommendations_p1?.recommendations_p1_date
                      ).format("DD/MM/YYYY")}
                    </>
                  )}
                </div>

                <div className="col-lg-6 tcol">
                  {report?.recommendations_p2?.recommendations_p2_desc}
                </div>
                <div className="col-lg-3 tcol">
                  {per2.firstName} {per2.lastName} - {per2.position}
                </div>
                <div className="col-lg-3 tcol">
                  {per2 && (
                    <>
                      תאריך יעד לביצוע:{" "}
                      {moment(
                        report?.recommendations_p2?.recommendations_p2_date
                      ).format("DD/MM/YYYY")}
                    </>
                  )}
                </div>

                <div className="col-lg-6 tcol">
                  {report?.recommendations_p3?.recommendations_p3_desc}
                </div>
                <div className="col-lg-3 tcol">
                  {per3.firstName} {per3.lastName} - {per3.position}
                </div>
                <div className="col-lg-3 tcol">
                  {per3 && (
                    <>
                      תאריך יעד לביצוע:{" "}
                      {moment(
                        report?.recommendations_p3?.recommendations_p3_date
                      ).format("DD/MM/YYYY")}
                    </>
                  )}
                </div>

                <div className="col-lg-6 tcol">
                  <b>רשימת תפוצה</b>
                  <br />
                  {report?.mailingList?.length > 0 && (
                    <>
                      {report.mailingList.map((item) => (
                        <>
                          {item.firstName} {item.lastName}, {item.position}
                          <br />
                        </>
                      ))}
                    </>
                  )}
                </div>
                <div className="col-lg-6 tcol">
                  <b>בכבוד רב,</b>
                  <br />
                  {userPersonalData.firstName} {userPersonalData.lastName}
                  <br />
                  {userPersonalData.signature && (
                    <>
                      <img
                        src={userPersonalData.signature}
                        style={{ width: 280 }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isExport ? (
              <>
                <input
                  type="text"
                  placeholder="שם הקובץ"
                  className="form-control"
                  style={{ width: 400, marginLeft: 10 }}
                  value={fileNameReq}
                  onChange={(e) => {
                    setFileNameReq(e.target.value);
                  }}
                />
                <button
                  onClick={handleDownloadPdf}
                  className="btn btn-success btn-lg"
                >
                  שמירת קובץ
                </button>
              </>
            ) : (
              <>
                <div style={{ width: 600 }}>
                  <div
                    className="form-control"
                    style={{
                      minHeight: 45,
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      padding: 5,
                    }}
                  >
                    {emails.map((email, index) => (
                      <span key={index} className="badge bg-primary me-2 mb-1">
                        {email}
                        <button
                          type="button"
                          className="btn-close btn-close-white ms-2"
                          aria-label="Remove"
                          onClick={() => removeEmail(email)}
                          style={{ fontSize: "0.6rem", marginLeft: 8 }}
                        ></button>
                      </span>
                    ))}
                    <input
                      type="text"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder="הוסף מייל ולחץ Enter או Tab"
                      style={{
                        flex: 1,
                        minWidth: 150,
                        border: "none",
                        outline: "none",
                      }}
                    />
                  </div>
                </div>
                <button
                  onClick={handleDownloadPdfSender}
                  className="btn btn-success btn-lg"
                >
                  שליחה לרשימת תפוצה
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default DebriefingEdit;
